export class QbFetchStatus extends Error {
    #fetch
    #error

    constructor(fetch) {
        super();
        this.#fetch = fetch;

        this.stack = this.stack.split(/\n/).find(l => !/\/qb-fetch\.js/.test(l));
        // this.stack = this.stack.replace(/\n.*/s, "\n")
    }

    get fetch() { return this.#fetch }

    get url() { return this.#fetch.url }

    get method() { return this.#fetch.method }

    get ok() { return this.#fetch.ok }

    get status() { return this.#fetch.status }

    get redirected() { return this.#fetch.redirected }

    get requestObject() {return this.#fetch.requestObject }

    get responseObject() {return this.#fetch.responseObject }

    get statusText() {
        const status = this.status;
        if(status) {
            const rv = [this.status, this.#fetch.statusText, this.errorMessage ].filter(n=>n);
            return this.redirected ? `${rv.join("|")} => ${this.responseObject.url}` : rv;
        }
        else return this.#error?.toString() || "Unknown";
    }

    get message() {
        return [this.method, this.url, this.statusText].join(" ")
    }

    get cause() {
        const rv = {request: this.requestObject};
        if(this.responseObject && Object.keys(this.responseObject).length) {
            rv.response = this.responseObject;
        }
        // if(this.#error) rv.error = this.#error.toString();
        return rv;
    }

    get debug() {
        this.name = "QbFetch";
        console.debug(this, this.cause);
    }

    get log() {
        console.error(this, this.cause);
    }

    set error(value) {
        this.name = this.constructor.name;
        this.#error = value;
    }
    get errorMessage() {
        return this.#error?.toString();
    }
}