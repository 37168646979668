Object.defineProperties(CustomElementRegistry.prototype, {
    nodeName: {
        value(klassName) {
            if('name' in klassName) klassName = klassName.name;
            return klassName.replace(/^_/, "").replace(/([a-z0-9])([A-Z])/g, "$1-$2").replace(/(.)([A-Z][a-z0-9])/g, '$1-$2').replace(/-+/g, '-').toLowerCase();
        }
    },
    registered: {value: []},
    register: {
        value(klass, nodeName) {
            if(!nodeName) {
                if("nodeName" in klass) nodeName = klass.nodeName;
                else nodeName = this.nodeName(klass);
            }
            const registry = this;
            nodeName = nodeName.toLowerCase();
            if(registry.get(nodeName)) return;
            registry.define(nodeName, klass);
            this.registered.push(nodeName);
        }
    }
});