if(!('clipboard' in navigator)) {
    Object.defineProperty(navigator, 'clipboard', {value: {}});

    Object.defineProperties(navigator.clipboard, {
        writeContent: {
            value(node) {
                navigator.clipboard.writeText(node.textContent);
            }
        },
        writeText: {
            async value(text) {
                const node = document.createElement("pre");
                node.style = "position:fixed;top:-100vh;left:-100vw"; //width:1px;height:1px;
                node.textContent = text;
                document.body.append(node);

                const selection = getSelection(),
                    oldrange = selection.rangeCount && selection.getRangeAt(0),
                    range = document.createRange();
                selection.removeAllRanges();
                range.selectNodeContents(node);
                selection.addRange(range);
                console.log(document.execCommand('copy'));
                node.remove();
                selection.removeAllRanges();
                if(oldrange) selection.addRange(oldrange);
            }
        }
    });
}
