/* Copyright 2022- Martin Kufner */

Object.defineProperties(document, {
    metas: {
        get() {
            const rv = {};
            for(const meta of this.getElementsByTagName('meta')) {
                const [name, ...parts] = meta.name.replace(/\]/g, '').split(/\[/);
                if(!parts.length) rv[name] = meta.content;
                else {
                    const content = {},
                        last = parts.pop();
                    parts.reduce((p, c) => p[c] = {}, content)[last] = meta.content;
                    rv[name] = content;
                }
            }
            for(const name in rv) {
                const n = name.replace(/[^a-z0-9_]([a-z])/gi, (_, m) => m.toUpperCase());
                if(n !== name) rv[n] = rv[name];
            }
            return rv;
        }
    },
    meta: {
        value: function (name, content, create = true) {
            let node = this.head.querySelector(`meta[name="${name}"]`);
            if(arguments.length === 1) return node?.content;
            if(content === undefined) return node?.remove();
            if(!node && create) {
                node = this.createElement('meta');
                node.name = name;
                this.head.append(node);
            }
            return node && (node.content = content);
        }
    },
    clearClass: {
        value(klass) {
            for(const n of this.getElementsByClassName(klass)) n.classList.remove(klass);
        }
    },
    removeElementsByClassName: {
        value(klass) {
            for(const n of this.getElementsByClassName(klass)) n.remove(klass);
        }
    },
    getMatchingCSSRules: {
        value(regexp) {
            Array.from(this.styleSheets).flatMap(s => Array.from(s.cssRules)).filter(r => regexp.test(r.selectorText)).map(r => r.cssText);
        }
    },
    setClass: {
        value(isSet, ...classList) {
            document.documentElement.classList[isSet ? "add" : "remove"](...classList);
        }
    },
    toggleDataset: {
        value(prop, value) {
            const ds = document.documentElement.dataset;
            if(ds[prop] === value) delete ds[prop];
            else ds[prop] = value;
        }
    },
    customPropertyNames: {
        get() {
            return Array.from(document.styleSheets)
                .filter(
                    sheet =>
                        sheet.href === null || sheet.href.startsWith(window.location.origin)
                )
                .reduce(
                    (acc, sheet) =>
                        (acc = [
                            ...acc,
                            ...Array.from(sheet.cssRules).reduce(
                                (def, rule) =>
                                    (def =
                                        rule.selectorText === ":root"
                                            ? [
                                                ...def,
                                                ...Array.from(rule.style).filter(name =>
                                                    name.startsWith("--")
                                                )
                                            ]
                                            : def),
                                []
                            )
                        ]),
                    []
                );
        }
    }
});
// if(!Object.getOwnPropertyDescriptor(document, "readyStatePromise")) {
//     Object.defineProperties(document, {
//         "readyStatePromise": {
//             get() {
//                 const resolve = {},
//                     value = {
//                         complete: new Promise(r => resolve.complete = r),
//                         interactive: new Promise(r => resolve.interactive = r)
//                     };
//                 Object.defineProperty(this, "readyStatePromise", {value, configurable: false, writable: false});
//
//                 const readyStateResolve = (state) => {
//                     switch(state) {
//                         case "complete":
//                             resolve.interactive("interactive");
//                             resolve.complete("complete");
//                             return true;
//                         case "interactive":
//                             resolve.interactive("interactive");
//                     }
//                 }
//                 if(!readyStateResolve(this.readyState)) this.addEventListener('readystatechange', () => readyStateResolve(document.readyState));
//                 return value;
//             },
//             configurable: true
//         },
//         stateComplete: {
//             get() { return this.readyStatePromise.complete; }
//         },
//         stateInteractive: {
//             get() { return this.readyStatePromise.interactive; }
//         }
//     });
// }


