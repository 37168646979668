// const remove = HTMLLinkElement.prototype.remove;
// Object.defineProperties(HTMLLinkElement.prototype, {
//     connectedCallback: {
//         value() {
//             super.connectedCallback();
//             console.log(this);
//         }
//     },
//     remove: {
//         value() {
//             console.log("remove", this);
//             remove.call(this);
//         }
//     }
// });
Object.defineProperties(CSSStyleSheet.prototype, {
    addOrReplaceRule: {
        value(css) {
            const selector = css.match(/^[^{]+/)[0].trim();
            const index = [...this.cssRules].findIndex(n => n.selectorText === selector);
            if(index === -1) return this.insertRule(css);
            this.insertRule(css, index);
            this.deleteRule(index + 1);
        }
    }
});

self.AdoptableStyleSheet = new (class {
    sheets = {}
    #observer

    constructor() {
        this.#observer = new MutationObserver(([{addedNodes}]) => this.#load(addedNodes));
        this.#observer.observe(document.head, {childList: true});
        this.#load(document.head.children);
    }

    #load(nodes) {
        for(const node of nodes) {
            if(node.matches('link[media="adoptable"]')) this.#loadLink(node);
            else if(node.matches('style[media="adoptable"]')) this.#loadStyle(node);
        }
    }

    #loadStyle(node) {
        const sheet = this.sheet(node.title);

        this.#replaceRules(sheet, node.sheet.cssRules);
    }


    #replaceRules(sheet, cssRules) {
        sheet.replaceSync("");
        for(const {cssText} of cssRules) sheet.insertRule(cssText);
    }

    #loadLink(link) {
        const style = link.href.replace(/^.*\/|(\.scss.*|-[a-zA-Z0-9_-]{8}.css)$/g, '');
        const sheet = this.sheet(style);
        Reflect.defineProperty(sheet, "href", {value: link.href});
        if(link.sheet) this.#replaceRules(sheet, link.sheet.cssRules);
        else link.addEventListener('load', () => this.#replaceRules(sheet, link.sheet.cssRules));
    }

    sheet(style) {
        return this.sheets[style] || (this.sheets[style] =  Object.assign(new CSSStyleSheet(),{style}));
    }

    replaceSync(style, css) {
        this.sheet(style).replaceSync(css);
    }
})();

const properties = {
    adoptStyleSheets: {
        value(...styles) {
            if('adoptedStyleSheets' in this) {
                const adopted = [...this.adoptedStyleSheets].map(s=>s.style);
                styles = styles.map(s=>{
                   if(s instanceof Array) {
                       const [style, css] = s;
                       if(css) AdoptableStyleSheet.sheet(style).replaceSync(css);
                       return style;
                   }
                   return s;
                });
                this.adoptedStyleSheets = [
                    ...this.adoptedStyleSheets,
                    ...styles.filter(s=>!adopted.includes(s)).map(s=>AdoptableStyleSheet.sheet(s))
                ];
            }
            else {
                this.insertAdjacentHTML('afterbegin', styles.map(s => {
                    if(!s.href) return console.error("IMPLEMENT <style> adoption");
                    return `<link media="all" rel="stylesheet" href="${s.href}" />`
                }));
            }
        }
    }
}
Object.defineProperties(document, properties);
Object.defineProperties(ShadowRoot.prototype, properties);

