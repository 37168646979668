Object.defineProperty(HTMLInputElement.prototype, 'radioNodeList', {
    get() {
        if (this.type !== 'radio' || !this.name) return null;
        const rv = [...document.querySelectorAll(`input[type="radio"][name="${this.name}"]`)];
        Object.defineProperties(rv, {
            checked: {
                get() { return this.find(n=>n.checked); }
            },
            value: {
                get() { return this.checked?.value || null; }
            }
        });
        return rv;
    }
});