/* Copyright 2022- Martin Kufner */
import {QbTime} from "./qb-time.js";

const OFFSET = new Date(1970, 0, 1) * 1;


export class QbTimer extends QbTime {
    static styleSheetTagNames = ["qb-time"];

    static get tick() {
        this.currentDate = new Date() * 1;
        requestAnimationFrame(() => {
            for(const n of document.getElementsByTagName("qb-timer")) n.tick;
            self.dispatchEvent(new CustomEvent("tick", {detail: this}));
        });
    }

    static format(format, date) {
        return new Date.Strftime(format).toString(Math.abs(date) + OFFSET);
    }

    static updateTitle(format, date) {
        const node = document.getElementsByTagName("title")[0];
        if(isNaN(date) || !format) {
            if("content" in node.dataset) node.textContent = node.dataset.content;
            delete node.dataset.content;
        }
        else {
            if(!("content" in node.dataset)) node.dataset.content = node.textContent;
            node.textContent = this.format(format, date);
        }
    }

    static get timers() {
        let min = 0, sum = 0, node;
        for(const n of document.getElementsByTagName("qb-timer")) {
            const duration = n.duration;
            if(duration >= 0) continue;
            if(n.paused) sum += duration;
            else if(min > duration) {
                min = duration;
                node = n;
            }
        }
        return {min, sum, node};
    }

    static get nodes() { return [...document.getElementsByTagName("qb-timer")] }

    static set pause(value) {
        for(const n of document.getElementsByTagName("qb-timer")) n.pause = value;
    }

    static get init() {
        if(this.timeoutId) return;
        this.tick;
        this.timeoutId = setInterval(() => this.tick, 1000);
    }

    static get halt() {
        clearInterval(this.timeoutId);
        delete this.timeoutId;
    }

    #current;
    #zero;
    started = false;
    get start() {
        this.setAttribute("started", "");
        this.removeAttribute("stopped");
        this.paused = false;
        this.started = true;
    }

    set pause(value) {
        if((this.paused = this.started && value)) this.setAttribute("paused", "");
        else this.removeAttribute("paused");
    }

    get stop() {
        this.removeAttribute("started");
        this.setAttribute("stopped", "");
        this.pause = this.started = false;
    }

    setup(seconds) {
        this.seconds = seconds;
        if(this.duration < 0) {
            this.setAttribute("started", "");
            this.started = this.paused = true;
        }
        else this.stop;
        return this;
    }

    get currentDate() { return this.constructor.currentDate }

    set current(value) {
        this.#current = value * 1;
        if(this.isBefore) return this.setAttribute("timer", "before");
        else if(this.isAfter) this.setAttribute("timer", "after");
        else if(this.isZero) {
            this.setAttribute("timer", "zero");
            this.dispatchEvent(new CustomEvent("zero"));
        }
        if(!this.classList.contains("no-stop-at-zero")) this.stop;
    }

    get current() { return this.#current || this.msecs() }

    set zero(value) {
        if(isNaN(value)) return this.removeAttribute("datetime");
        const date = this.now(value);
        this.#zero = date * 1;
        this.setAttribute("datetime", date.toZoneISOString());
    }

    get zero() { return this.#zero }

    $datetime(value) {
        this.#zero = (Object.present(value) ? this.now(value) : new Date("Invalid")) * 1;
    }

    set seconds(value) { this.duration = Math.round(value * 1000) }

    get seconds() { return this.duration / 1000 }

    set duration(value) {
        if(typeof value !== "number" || isNaN(value)) return this.stop;
        this.zero = this.msecs() + Math.abs(value);
        this._render;
    }

    get duration() {
        const rv = this.current - this.zero;
        return rv;
    }

    get diffSeconds() { return Math.round(this.duration / 1000) * 1000 }

    get isBefore() { return this.diffSeconds < 0 }

    get isAfter() { return this.diffSeconds > 0 }

    get isZero() { return this.diffSeconds === 0 }

    #lastDate;
    get tick() {
        if(this.getAttribute("datetime")) {
            if(this.paused) this.zero += this.currentDate - (this.#lastDate || 0);
            else if(this.started) {
                this.current = this.currentDate;
                this._render;
            }
        }
        this.#lastDate = this.currentDate;
    }

    get _render() {
        this.date = Math.abs(this.duration) + OFFSET;
    }
}

self.QbTimer = QbTimer;
QbTimer.init;
QbTimer.register;