/* Copyright 2022- Martin Kufner */


// Object.slice = (object, ...keys) => Object.fromEntries(Object.entries(object).filter(([k, v]) => keys.indexOf(k) != -1));
Object.defineProperties(Object, {
    definePropertiesUnlessDefined: {
        value(object, properties) {
            for(const [property, descriptor] of Object.entries(properties)) {
                if(object.hasOwnProperty(property)) delete properties[property];
            }
            Object.defineProperties(object, properties);
        }
    },

    make: {
        value(...args) {
            const rv = {}
            while(args.length > 1) rv[args.shift()] = args.shift();
            return rv;
        }
    },
    slice: {
        value(object, ...keys) {
            const rv = {};
            for(const k of keys.flat()) if((k in object) && typeof k !== 'function') rv[k] = object[k];
            return rv;
        }
    },
    type: {
        value(object) {
            if(object === undefined) return "undefined";
            else if(object === null) return "null";
            return object.__proto__.constructor.name;
        }
    },
    except: {
        value(object, ...keys) {
            return Object.fromEntries(Object.entries(object).filter(([k, v]) => keys.indexOf(k) === -1))
        }
    },

    compact: {
        value(object) {
            return Object.fromEntries(Object.entries(object).filter(([k, v]) => v !== undefined && v !== null))
        }
    },
    self_compact: {
        value(object) {
            Object.entries(object).forEach(([k, v]) => { if(v === undefined || v === null) delete object[k] });
            return object;
        }
    },
    dig: {
        value(object, ...keys) {
            let digged = object;
            for(const key of keys) {
                if(typeof digged === 'undefined' || digged === null) return undefined;
                if(typeof key === 'function') digged = key(digged);
                else digged = digged[key];
            }
            return digged;
        }
    },

    blank: {
        value(o) {
            switch(Object.type(o)) {
                case "Object":
                    o = Object.keys(o);
                case "Array":
                    return !o.length;
                case "String":
                    return o === "";
                case "Date":
                case "Number":
                    return isNaN(o);
                case "undefined":
                case "null":
                    return true
            }
            return false;
        }
    },
    subset: {
        value(o1, o2, subset = true) {
            const t1 = Object.type(o1), t2 = Object.type(o2);
            if(t1 !== t2) return false;
            if(t1 !== "Object" && t1 !== "Array") return o1 === o2;
            const keys = Object.keys(o1);
            if(!subset && keys.length !== Object.keys(o2).length) return false;
            for(const key of keys) {
                if(!Object.subset(o1[key], o2[key], subset)) return false;
            }
            return true;
        }
    },


    extend: {
        value(object, ...modules) {
            modules.reverse().forEach(module => {
                if(module === object) return;
                const {prototype, ...descriptors} = Object.getOwnPropertyDescriptors(module);
                Object.defineProperties(object, descriptors);
                module.extended?.call(module, object);
            })
            return object;
        }
    },

    include: {
        value(object, ...modules) {
            modules.reverse().forEach(module => {
                if(module === object) return;
                const {constructor, ...prototype} = Object.getOwnPropertyDescriptors(module.prototype);
                Object.defineProperties(object.__proto__, prototype);
                module.included?.call(module, object);
            })
            return object;
        }
    },

    present: {value(o) { return !Object.blank(o); }},
    presence: {value(o) { return Object.blank(o) ? null : o; }},

    isObject: {value(o) { return o != null && typeof o === 'object'; }},
    equal: {value(o1, o2) { return Object.subset(o1, o2, false); }},

    delete: {
        value(which, attribute) {
            const rv = which[attribute];
            delete which[attribute];
            return rv
        }
    },

    getPropertyDescriptors: {
        value(object) {
            const props = {},
                attributes = Object.keys(object) + ["constructor", "__lookupSetter__", "__lookupGetter__", "__defineSetter__", "__defineGetter__"];
            let obj = object;
            do { Object.assign(props, Object.getOwnPropertyDescriptors(obj)); } while(obj = Object.getPrototypeOf(obj));
            return Object.fromEntries(Object.entries(props).filter(([e, d]) => attributes.indexOf(e) === -1 && typeof (d.get || d.set || d.value) == 'function'));
        }
    },

    getPropertyNames: {value(object) { Object.keys(Object.getPropertyDescriptors(object)); }},

    deep: {
        value(object, callback, ...keys) {
            try {
                Object.entries(object).forEach(([k, v]) => {
                    if(Object.type(v) == "Object") return Object.deep(v, callback, k, ...keys);
                    if(callback(v, ...[k, ...keys].reverse()) === false) throw 'break';
                });
            }
            catch(e) {}
        }
    },

});

if(typeof Object.fromEntries !== "function") {
    Object.fromEntries = (obj) => obj.reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
}


/*################ to be refactored
Object.extend = (target, src, included) => {
    const skip_props = ['constructor', 'length', 'name', 'prototype', 'included', 'extended'];
    Object.entries(Object.getOwnPropertyDescriptors(src)).filter(([prop, def])=>skip_props.indexOf(prop)==-1)
        .forEach(([prop, def])=> Object.defineProperty(target, prop, def));
    Object.assign(target, src);
    if(!included && typeof src.extended == 'function') src.extended(target);
    if(src.__proto__.constructor.name !== 'Object') Object.extend(target, src.__proto__, included);
}

Object.include = (target, src) => {
    Object.extend(target.prototype, src.prototype, true);
    if(typeof src.included == 'function') src.included(target);
}

Object.updates = (superset, subset) => {
    if(Object.type(superset) !== Object.type(subset)) return subset;
    const isObject = object => object != null && typeof object === 'object';
    if(!isObject(superset)) return superset === subset ? null : subset;
    const updates = {}, keys1 = Object.keys(subset);
    for (const key of keys1) {
        const val1 = subset[key], val2 = superset[key];
        if (val1 !== val2) updates[key] = val1;
    }
    return updates;
}

Object.deepdiff = (object1, object2, subset) => {
    if(typeof object1 !== typeof object2) return [object1, object2];
    const isObject = object => object != null && typeof object === 'object';
    if(!isObject(object1)) return object1 === object2 ? [] : [object1, object2];

    const keys1 = Object.keys(object1),
        keys2 = Object.keys(object2);
    if (!subset && keys1.length !== keys2.length) return [object1, object2];
    for (const key of keys1) {
        const val1 = object1[key], val2 = object2[key], areObjects = isObject(val1) && isObject(val2);
        if (areObjects && Object.diff(val1, val2, subset).length || !areObjects && val1 !== val2) return Object.fromEntries([[key,[val1, val2]]]);
    }
    return [];
}
 */