/* Copyright 2022- Martin Kufner */
import {QbElement} from "./qb-element.js"

const template = document.createElement('template');
template.innerHTML = `
    <span tabIndex="0" class='taborder'></span>
    <div>
        <button id="close"></button>
        <header><slot name="header">Overlay</slot>
        <div><slot></slot>
        <nav><slot name="nav"></slot></nav>
    </div>
    <span tabIndex="0" class='taborder'></span>
    `;

export class QbOverlay extends QbElement {
    static observedAttributes = ['open'];
   static shadow = { mode: 'open', sheets: ["qb-elements"] };
    static template = template;

    constructor() {
        super();
        this.closeNode = this.contentRoot.getElementById('close');
        this.contentRoot.querySelectorAll('.taborder').forEach(n => n.addEventListener("focus", (e => e.relatedTarget.focus())));
        this.focusFrom = document.activeElement;
    }

    $open(value) {
        if(value !== null) {
            document.querySelectorAll('qb-overlay').forEach(n => n !== this && (n.open = false));
            this.closeNode.focus();
        }
        else {
            this.focusFrom.focus();
            if(this.classList.contains("once")) this.remove();
        }
    }

    set open(value) {
        if(value) this.setAttribute("open", "");
        else this.removeAttribute("open");
    }

    handleEvent_close(evt) {
        const target = evt.currentTarget;
        if(target === this || target.matches("#close, .close")) this.open = false;
    }

    connectedCallback() {
        for(const node of this.parentElement.getElementsByTagName("qb-overlay")) {
            // console.log(node, this, node !== this);
            if(node !== this && !node.classList.contains("persist")) node.remove();
        }
        this.events({click: "close"});
    }
}

QbOverlay.register;