import {QbParticipantPerson} from "./base"
export class QbMc extends QbParticipantPerson {

    get defaults() {
        return {
            avatar: '🎓',
            name: 'Mc'
        };
    }

    get identifier() {
        return JSON.stringify({
            itemtype: "Role",
            itemid: this.itemid,
            vendor_id: this.vendor_id
        });
    }
    get vendor_id() {
        if(self.Show) return Show.vendor_id;
        return this.closest("[data-vendor_id]")?.dataset.vendor_id;
    }

    set vendor_id(_) {}
}
QbMc.register;