let cnt = 0;

class ElementSelection {
    anchorNode
    anchorOffset
    focusNode
    focusOffset
    type
    isCollapsed

    #inside
    #range


    constructor(root) {
        this.root = root;
        setTimeout(()=> root.dataset.cnt = ++cnt);
        this.#listen;
    }

    get #selection() {
        let selection = self.getSelection();
        if(selection.focusNode && selection.anchorNode && this.root.contains(selection.focusNode)) return selection;
    }

    get range() {
        if(!this.#range) {
            switch(this.type) {
                case "None":
                    this.#range = new Range();
                    return this.#range;
                case "Caret":
                    this.#range = new Range();
                    this.#range.setStart(this.focusNode, Math.min(this.focusNode.childNodes.length, this.focusOffset));
                    this.#range.setEnd(this.focusNode, Math.min(this.focusNode.childNodes.length, this.focusOffset));
                    break;
                case "Range":
                    this.#range = getSelection().getRangeAt(0);
                    break;
            }
        }
        return this.#range;
    }

    get currentSelection() {
        const selection = this.#selection;
        const {anchorNode, anchorOffset, focusNode, focusOffset, type, isCollapsed} = selection || {};
        return {anchorNode, anchorOffset, focusNode, focusOffset, type, isCollapsed};
    }

    set currentSelection(currentSelection) {
        const selection = this.#selection;
        const {anchorNode, anchorOffset, focusNode, focusOffset} = currentSelection;
        selection.setBaseAndExtent(anchorNode, anchorOffset, focusNode, focusOffset);
    }

    handleEvent(evt) {
        const selection = this.currentSelection;
        if(!selection?.focusNode) {
            if(this.#inside) {
                this.root.dispatchEvent(new CustomEvent('selectionleave', {detail: this}));
                this.#inside = false;
                this.#range = null;
            }
            return;
        }
        if(!this.#inside) {
            this.#inside = true;
            this.root.dispatchEvent(new CustomEvent('selectionenter', {detail: this}));
        }
        this.#range = null;
        Object.assign(this, selection);
        this.root.dispatchEvent(new CustomEvent('selectionchange', {detail: this}));
    }

    get #listen() {
        document.addEventListener("selectionchange", this, {passive: true});
    }

    get #unlisten() {
        document.removeEventListener("selectionchange", this, {passive: true});
    }

    get disconnect() {
        this.#unlisten;
        delete this.root.__elementSelection__;
        delete this.root;
        delete this.anchorNode;
        delete this.focusNode;
    }

    focus(node) {
        this.#unlisten;
        const selection = this.getSelection;
        if(!this.focusNode) {
            const children = [...this.root.querySelectorAll('[tabindex], [contenteditable]')];
            if(!node) node = children.find(n => n.contentEditable || parseInt(n.tabIndex) >= 0);
            if(node) {
                this.#select(node, true);
                node.focus();
            }
        }
        else selection.setBaseAndExtent(this.anchorNode, this.anchorOffset, this.focusNode, this.focusOffset);
        this.#listen;
    }

    #select(node, collapseToEnd) {
        const selection = this.getSelection;
        const range = new Range();
        range.selectNodeContents(node);
        selection.removeAllRanges();
        selection.addRange(range);
        if(collapseToEnd === true) selection.collapseToEnd();
        else if(collapseToEnd === false) selection.collapseToStart();
        const {focusNode, focusOffset, anchorNode, anchorOffset, direction, isCollapsed} = selection;
        Object.assign(this, {focusNode, focusOffset, anchorNode, anchorOffset, direction, isCollapsed});
    }

    toString() {
        return this.range?.toString();
    }

    selectAllChildren(node) {
        this.#select(node);
    }
    selectStart(node) {
        this.#select(node, false);
    }
    selectEnd(node) {
        this.#select(node, true);
    }

    insert(...nodes) {
        const range = this.range;

        const contents = range.extractContents();
        if(nodes.length) range.insertNode(...nodes.map(n => (n instanceof Node) ? n : new Text(n.toString())));
        const selection = this.getSelection;
        selection.removeAllRanges();
        selection.addRange(range);
        selection.collapseToEnd();
        const {focusNode, focusOffset, anchorNode, anchorOffset, direction, isCollapsed} = selection;
        Object.assign(this, {focusNode, focusOffset, anchorNode, anchorOffset, direction, isCollapsed});
        return contents;
    }

    get delete() {
        return this.range.extractContents();
    }

    modify(alter, direction, granularity) {
        self.getSelection().modify(alter, direction, granularity);
        this.#range = null;
        Object.assign(this, this.currentSelection);
        return this.range;
    }
}

Object.defineProperties(HTMLElement.prototype, {
    elementSelection: {
        get() {
            return this.__elementSelection__;
        },
        set(value) {
            if(/^(INPUT|TEXTAREA)$/.test(this.nodeName)) return console.warn('Cant set elementSelection on INPUT OR TEXTAREA');
            if(value) {
                if(this.__elementSelection__) return;
                this.__elementSelection__ = new ElementSelection(this);
                // this.addEventListener('selectionchange', e => {
                //     const {
                //         focusNode,
                //         focusOffset,
                //         anchorNode,
                //         anchorOffset,
                //         direction,
                //         isCollapsed,
                //         startContainer,
                //         startOffset,
                //         endContainer,
                //         endOffset
                //     } = e.detail;
                //     console.log({
                //         focusNode,
                //         focusOffset,
                //         anchorNode,
                //         anchorOffset,
                //         direction,
                //         isCollapsed,
                //         startContainer,
                //         startOffset,
                //         endContainer,
                //         endOffset
                //     })
                //     console.log(e.detail.toString())
                // });
            }
            else this.__elementSelection__?.disconnect;
        }
    }
});

