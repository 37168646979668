/* Copyright 2022- Martin Kufner */
import {QbElement} from "./qb-element.js"

export class QbTime extends QbElement {
    #date
    #duration
    #observer
    #strftime
    static shadow = {mode: 'closed', sheets: ["qb-elements"]}

    static observedAttributes = ['datetime', 'format', 'disabled', 'open'];
    static template = '<slot part="input"></slot>';

    constructor() {
        super();
        this.addEventListener("click", this);
    }

    handleEvent(evt) {
        if(!this.firstElementChild) return;
        switch(evt.type) {
            case "click": return this.toggleAttribute("open");
            case "change": return this.date = this.firstElementChild.value;
        }
    }

    $open(value) {
        if(!this.firstElementChild) return;
        if(value !== null) this.firstElementChild.showPicker();
    }

    $disabled(value) {
        if(!this.firstElementChild) return;
        if(value) {
            this.removeAttribute("open");
            this.setAttribute("disabled", "");
            this.firstElementChild.disabled = true;
        }
        else {
            this.removeAttribute("disabled");
            this.firstElementChild.disabled = false;
        }
    }

    connectedCallback() {
        this.#changes(this.childNodes[0]);
        this.#observer = new MutationObserver((records, observer)=>this.#changes(records[0].addedNodes[0]));
        this.#observer.observe(this, { characterData: true, childList: true });
    }

    now(...args) {
        return new Date(...args);
    }

    msecs(...args) {
        return this.now(...args) * 1;
    }

    #changes(node) {
        if(!node) return;
        if(node instanceof Text) this.date = node.data;
        else if(node instanceof HTMLInputElement) {
            this.date = node.value;
            node.addEventListener("change", this);
            this.$disabled(node.disabled);
        }
    }

    parse(value) {
        return Date.parse(value);
    }

    get clear() {
        this.removeAttribute("datetime");
        this.removeAttribute("duration");
    }

    get #render_clear() {
        for(const node of this.nodes) {
            for(const n of node.children) {
                if(n.nodeName !== "I") return n.remove();
                delete n.dataset.value;
                n.textContent = "";
            }
        }
    }

    #render(node) {
        const {format, type, flag, pad} = node.dataset;
        if(type === "duration") { return console.log(node); }
        const value = node.method();
        if(/^\d+$/.test(value)) node.dataset.value = value;
        else if(!value) node.dataset.value = "";
        else node.dataset.value = value;

        if(isNaN(value)) {
            const n = node.firstElementChild;
            n.dataset.value = n.textContent = value;
            return;
        }
        const formatted = [...value.toString()];
        for(const n of [...node.children].reverse()) {
            const value = formatted.pop();
            n.dataset.value = value || pad || "";
            n.textContent = n.dataset.value.replace(/\s/g, "\u00A0");
        }
        formatted.forEach(value => cT.B({afterbegin: node, dataset: {value}}, value));
    }

    get nodes() { return [...this.contentRoot.querySelectorAll("[data-format]")] }

    get render() {
        this.#render_clear;
        if(Object.blank(this.#date)) return;
        this.#strftime.date = this.#date;
        for(const node of this.nodes) this.#render(node);
    }

    get date() { return this.#date; }

    set date(date) {
        this.setClass(!date, "zero");
        if(this.#date === date) return;
        if(typeof date === "number") date = this.now(date);
        if(typeof date === "string") {
            if(isNaN(date)) date = Date.parse(date);
            else date = this.now(parseInt(date));

        }
        if(isNaN(date)) throw 'not a date';
        if(date && !date instanceof Date) throw 'not a date';
        this.#date = date;
        const localtime = date.toZoneISOString()
        this.setAttribute("localtime", localtime);
        this.setClass(localtime.startsWith('1970-01-01T00:00:00'), "zero");

        this.#duration = date.getDifference();
        if(this.#strftime) this.render;
    }

    get value() { return this.dateTime }

    set value(value) { this.dateTime = value }

    $datetime(value) {
        this.date = Object.present(value) ? this.now(value) : new Date("Invalid");
    }

    set dateTime(value) {
        if(/number|string/.test(typeof value)) value = this.parse(value);
        if(Object.blank(value)) return this.clear;
        this.setAttribute("datetime", value.toZoneISOString());
    }


    // adjust(unit, value) {
    //     if (isNaN(parseInt(value))) return this.date = value;
    //     if (!this.#date) this.#date = new Date();
    //     this.#date[`set${unit}`](value);
    //     this.render;
    // }

    // get seconds() {
    //     return this.#date?.getSeconds();
    // }
    //
    // set seconds(value) {
    //     this.adjust("Seconds", value);
    // }
    //
    // get minutes() {
    //     return this.#date?.getMinutes();
    // }
    //
    // set minutes(value) {
    //     this.adjust("Minutes", value);
    // }
    //
    // get hours() {
    //     return this.#date?.getHours();
    // }
    //
    // set hours(value) {
    //     this.adjust("Hours", value);
    // }
    //
    // get days() {
    //     return this.#date?.getDays();
    // }
    //
    // set days(value) {
    //     this.adjust("Days", value);
    // }
    //
    // get months() {
    //     return this.#date?.getMonths();
    // }
    //
    // set months(value) {
    //     this.adjust("Months", value);
    // }
    //
    // get years() {
    //     return this.#date?.getYears();
    // }
    //
    // set years(value) {
    //     this.adjust("Years", value);
    //
    // }


    // $nodeDuration(flag, format) {
    //
    // }


    // $formatNode(format, flag, type) {
    //     const _format = format;
    //     const opts = {beforeend: this.shadowRoot};
    //     if (type === "?") type = "duration";
    //     else if (/[zZ]/.test(_format)) opts.data.type = "zone";
    //     else if (/[HkIlPpMSLNs]/.test(_format)) type = "time";
    //     else type = "date";
    //     let places = 1, pad = '0';
    //     if (/[YGg]/.test(_format)) places = 4;
    //     else if (/[jL]/.test(_format)) places = 3;
    //     else if (/[yCmdIMSUWVHI]/.test(_format)) places = 2;
    //     else if (/[ekl]/.test(_format)) {
    //         places = 2;
    //         pad = " ";
    //     }
    //     else pad = undefined;
    //     const html = `<i></i>`.repeat(places);
    //     return DIV({html, dataset: {format, pad, flag, type}, ...opts});
    // }



    $format(value) {
        for(const n of this.contentRoot.children) {
            if(n.nodeName !== "SLOT") n.remove();
        }
        this.#strftime = new Date.Strftime(value);
        let i = 0;
        for(let p of this.#strftime.parts) {
            const opts = {beforeend: this.contentRoot, classList: []};
            let part = false;
            switch(typeof p) {
                case "string":
                    part = `_${++i}`
                    SPAN(opts, {part, style: {gridArea: part}}, p); //.replace(/\s/g, "\u00A0"));
                    break;
                case "function":
                    const {places, ...dataset} = p;
                    part = dataset.format.replace(/%/g, '');
                    SPAN({
                        innerHTML: `<i></i>`.repeat(places),
                        part,
                        style: {gridArea: part},
                        dataset, ...opts
                    }).method = p;
                    break;
            }
        }
        if(this.#date) this.render;
    }

    set format(value) { this.setAttribute('format', value)}

    get format() { return this.getAttribute('format')}
}

QbTime.register;