import {POST, PATCH, DELETE} from "../qb3-element";
const entities = {}
let rule_index, load = [], styleSheet;
self.QbEntity = class {
    static async parseStyleSheet() {
        rule_index = 0;
        styleSheet = AdoptableStyleSheet.sheet("slots");
        document.adoptStyleSheets("slots");
        [...styleSheet.cssRules].forEach(rule => {
            let match = rule.selectorText.match(/^\[itemid="([0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12})"],\s*\[id\$="_([0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12})"]$/);
            if(!match) return;
            const itemid = match[1];
            const data = {
                name: (rule.cssText.match(/--slot-name:\s*"((?:\\"|[^"])*)"/) || [""])[0],
                avatar: (rule.cssText.match(/--slot-avatar:\s*"((?:\\"|[^"])*)"/) || [""])[0],
                index: parseInt((rule.cssText.match(/--slot-index:\s*"(\d+)"/) || [0])[0]),
                color: (rule.cssText.match(/--slot-color:\s*([^;])/) || [])[0]
            };
            new this(itemid, data);
        });
    }

    static async get(node) {
        if(rule_index === undefined) await this.parseStyleSheet();
        const {itemid, nodeName} = node;
        let rv = entities[itemid];
        if(rv) {
            if(rv instanceof Promise) return rv;
            else return Promise.resolve(rv);
        }
        const {promise, resolve} = Promise.withResolvers();
        entities[itemid] = promise;
        entities[itemid].resolve = resolve;
        load.push([nodeName, itemid]);
        if(load.length !== 1) return;
        setTimeout(()=>{
           const avatars = load.splice(0).reduce((acc, [key, id]) => {
                if (!acc[key]) acc[key] = [];
                acc[key].push(id);
                return acc;
            }, {});
            const body = {avatars};
            if(typeof Show === "object") body.show_id = Show.itemid;
            POST("/avatar", {body}).result({
                json: ({avatars}) => {
                    for(const {itemid, ...data} of avatars) {
                        // const {itemid, ...data} = avatar;
                        if(entities[itemid] instanceof Promise) {
                            const promise = entities[itemid],
                                entity = new this(itemid);
                            entity.update(data);
                            promise.resolve(entity);
                        }
                    }
                }
            });
        }, 10);
        return promise;
    }

    static async name(node, value) {
        const entity = await this.get(node);
        if(value !== undefined) entity.name = value;
        return entity.name;
    }
    static async color(node, value) {
        const entity = await this.get(node);
        if(value !== undefined) entity.color = value;
        return entity.color;
    }
    static async avatar(node, value) {
        const entity = await this.get(node);
        if(value !== undefined) entity.avatar = value;
        return entity.avatar;
    }
    static async index(node, value) {
        const entity = await this.get(node);
        if(value !== undefined) entity.index = value;
        return entity.index;
    }
    static async update(node, value) {
        const entity = await this.get(node);
        return entity.update(value);
    }

    static async init(node, value) {
        if(rule_index === undefined) await this.parseStyleSheet();
        const {itemid, nodeName} = node;
        let rv = entities[itemid] || new this(itemid);
        if(rv instanceof Promise) rv = await rv;
        return rv.update(value);
    }

    #cssProperties = {}
    #data = {}

    constructor(itemid, data) {
        this.itemid = itemid;
        this.rule_index = rule_index++;
        entities[itemid] = this;
        if(typeof data === "object") Object.assign(this.#cssProperties, data);
    }


    get #nameCSS() {
        const value = this.#cssProperties.name;
        return value ? `--slot-name:"${value.replace(/"/g, '\\"')}";` : "";
    }
    get #colorCSS() {
        const value = this.#cssProperties.color;
        return value ? `--slot-color:${value};` : "";
    }
    get #avatarCSS() {
        const value = this.#cssProperties.avatar
        return value ? `--slot-avatar:"${value.replace(/"/g, '\\"')}";` : "";
    }
    get #indexCSS() {
        const value = this.#cssProperties.index;
        return value ? `--slot-index:"${value}";--slot-index-num:${value};` : "";
    }

    get name() { return this.#cssProperties.name }
    set name(name) { this.update({name}) }
    get color() { return this.#cssProperties.color }
    set color(color) { this.update({color}) }
    get avatar() { return this.#cssProperties.avatar }
    set avatar(avatar) { this.update({avatar}) }
    get index() { return this.#cssProperties.index }
    set index(index) { this.update({index}) }

    get data() { return this.#data }

    #writeRules() {
        const cssText = [this.#nameCSS, this.#colorCSS, this.#avatarCSS, this.#indexCSS].filter(css=>css).join(""),
            selector = `[itemid="${this.itemid}"], [id$="_${this.itemid}"]`;
        if(styleSheet.cssRules.length > this.rule_index + 1) styleSheet.deleteRule(this.rule_index);
        styleSheet.insertRule(`${selector} { ${cssText} }`, this.rule_index);
    }

    update(value) {
        const {name, color, avatar, index, itemtype, data} = value;
        let cssChanged = false;
        if(('data' in value)) Object.assign(this.#data, data);
        if(('itemtype' in value) && this.itemtype !== itemtype) this.itemtype = itemtype;
        if(('name' in value) && this.name !== name) { cssChanged = true; this.#cssProperties.name = name; }
        if(('color' in value) && this.color !== color) { cssChanged = true; this.#cssProperties.color = color; }
        if(('avatar' in value) && this.avatar !== avatar) { cssChanged = true; this.#cssProperties.avatar = avatar; }
        if(('index' in value) && this.index !== index) { cssChanged = true; this.#cssProperties.index = index; }
        if(cssChanged) this.#writeRules();
        return this.#data;
    }
}