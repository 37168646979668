/* Copyright 2022- Martin Kufner */
import {QbInputElement} from "./qb-element.js"

class QbTextInput extends QbInputElement {
   static shadow = { mode: 'open', sheets: ["qb-elements"] };
    constructor() {
        super();
        cT('label', {beforeend: this.contentRoot},
            this.label = cT("span"),
            this.textarea = cT('textarea')
        );
        this.textarea.style.height = `${this.textheight}px`;
        this.textarea.addEventListener('input', this, false);
        this.textarea.addEventListener('change', this, false);

    }

    connectedCallback() {
        super.connectedCallback();
        new IntersectionObserver(([{isIntersecting}])=>isIntersecting && this.resize,
            {root: this.parentElement}
        ).observe(this);
    }

    focus() {
        this.textarea.focus();
    }


    static observedAttributes = ['value', 'name', 'readonly', 'maxlength', 'placeholder', 'headline'];

    set length(value) { this.label.dataset.length = value }

    handleEvent(evt) {
        switch(evt.type) {
            case "input":
                this.resize;
                this.updateChars;
                // this.dispatchEvent(new evt.constructor(evt.type, evt));
                this.dispatchEvent(evt.cloneEvent());
                break;
            case "change":
                evt.stopImmediatePropagation();
                this.dispatch("change", {value: this.value} );
                break;
        }
    }

    get updateChars() {
        if(this._maxlength === undefined) return delete this.label.dataset.remaining;
        this.label.dataset.remaining = this.remaining;
    }

    get length() { return [...this.value].length }

    get remaining() {
        let remaining = this._maxlength - this.length;
        return remaining > 0 ? `+${remaining}` : remaining;
    }

    get maxlength() { return this._maxlength }

    set maxlength(value) {
        value = parseInt(value);
        if(value) {
            this.label.dataset.maxlength = this._maxlength = value;
        }
        else {
            delete this._maxlength;
            delete this.label.dataset.maxlength;
        }
        this.updateChars;
    }

    get textheight() {
        const lineHeight = parseFloat(window.getComputedStyle(this.textarea).fontSize);
        return this.textarea.scrollHeight  + lineHeight * 1.4;
    }

    get resize() {
        this.textarea.style.height = 0;
        this.textarea.style.height = `${this.textheight}px`;
    }

    set value(value) {
        this.textarea.value = value;
        this.resize;
        this.updateChars;
    }

    get value() { return this.textarea.value }

    set placeholder(value) {
        this.textarea.placeholder = value;
    }

    set headline(value) {
        this.label.textContent = value;
    }
}
QbTextInput.register