import {FETCH, GET} from '@qb-assets-js/qb-fetch'
import '@qb-assets-js/dom-extensions'

window.addEventListener('storage', e=>e.key === 'QbAuthToken' && (location.pathname.startsWith("/auth") ? location.pathname = "/" : location.reload()));
document.stateInteractive.then(()=>localStorage.setItem('QbAuthToken', document.metas.QbAuthToken) )

class QbAuth extends HTMLElement {
    static observedAttributes = ["href"]

    attributeChangedCallback(name, oldValue, newValue) {
        if(newValue === null) return;
        this.removeAttribute("href");
        if(!/^\/auth\//.test(newValue)) debugger;
        this.#request(newValue);
    }

    static get token() {
        return localStorage.getItem('QbAuthToken')
    }

    #shadowRoot
    #contentRoot

    constructor() {
        super();
        this.#shadowRoot = this.attachShadow({mode: "closed"});
        this.#shadowRoot.append(DIV({class: 'shade', events: {click:()=>history.back()}}),
            this.#contentRoot = DIV({class: 'container'}));
        this.#shadowRoot.adoptStyleSheets("qb-auth");
    }

    connectedCallback() {
        window.history.replaceState({}, "", "/auth");
        if(this.children.length) {
            const children = new DocumentFragment();
            children.append(...this.children);
            return this.#insert(children);
        }
    }

    #request(pathname, options = {}) {
        options.headers = { "X-QB-AUTH": "TRUE" }
        return FETCH(pathname, options).result({
            wholePage: (doc, f) => {
                console.warn("Wholepage reload");
                document.open('text/html');
                document.write(doc);
                document.close();
                // history.replaceState({}, null, f.url)
            },
            fragment: body => {
                this.#insert(body);
            },
            redirected: href => {
                if(href.startsWith("/auth/")) this.setAttribute('href', href);
                else location = href;
            }
        });
    }

    #insert(fragment) {
        this.#contentRoot.textContent = "";
        this.#contentRoot.append(fragment);
        for(const node of this.#contentRoot.querySelectorAll("form")) {
            node.addEventListener('submit', e => this.handleEvent_submit(e));
            node.addEventListener('formdata', e => this.handleEvent_formdata(e));
            node.addEventListener('reset', e => this.handleEvent_back(e));
        }
        for(const node of this.#contentRoot.querySelectorAll('a:not([href="/auth"])')) {
            node.addEventListener('click', e => this.handleEvent_click(e));
        }
        this.#shadowRoot.getElementById('disclose')?.addEventListener("input", e => this.handleEvent_disclose(e));
    }

    handleEvent_disclose(evt) {
        const node = evt.currentTarget;
        if(node.checked) {
            for(const n of this.#contentRoot.querySelectorAll('[type^="password"]')) {
                n.classList.add('password_revealed');
                n.type = "text";
            }
        }
        else {
            for(const n of this.#contentRoot.querySelectorAll('.password_revealed')) {
                n.classList.remove('password_revealed');
                n.type = "password";
            }
        }
    }

    handleEvent_click(evt) {
        if(evt.currentTarget.target || !new URL(evt.currentTarget.href).pathname.startsWith("/auth/")) return;
        evt.preventDefault();
        this.#request(evt.currentTarget.href, {method: "get"});
    }

    handleEvent_submit(evt) {
        evt.preventDefault();
        new FormData(evt.currentTarget);
    }

    handleEvent_back(evt) {
        evt.preventDefault();
        location.reload();
    }

    handleEvent_formdata(evt) {
        const form = evt.currentTarget;
        this.#request(form.action, {
            method: form.method,
            body: evt.formData
        });
    }
}

customElements.register(QbAuth)
