import {QbElement} from "@qb-assets-js/qb-elements/qb-element.js"

self.closeQbSelect = () => document.querySelectorAll("qb-select").forEach(n => n.open = false);


class QbSelect extends QbElement {
    static shadow = {mode: 'open', sheets: ["qb-elements"]}
    static observedAttributes = ['summary', 'name'];

    #detailsNode
    #summaryNode
    #addOptionMethod

    constructor() {
        super();
        this.#addOptionMethod = this.append;
        this.contentRoot.append(
            SLOT({name:"label"}),
            this.#detailsNode = DETAILS({class: "select", part: "details", events: [this, "toggle"]},
                this.#summaryNode = SUMMARY({part: "summary"}, "⤵️"),
                SLOT({
                    part: "options",
                    events: [this, "slotchange"]
                }))
        );
    }

    set addOptionMethod(value) {
        this.#addOptionMethod = value.bind(this);
    }

    set options(value) {
        if(!(value instanceof Array)) value = Object.entries(value);
        value.forEach(v => this.option = v)
    }

    set option(value) {
        this.#addOptionMethod(...value);
    }

    #name
    $name(value) {
       this.querySelectorAll(`[name^="${this.#name}"]`).forEach(n => n.name = value + n.name.slice(value.length));
       this.#name = value;
    }

    get name() { return this.#name; }

    $summary(value) {
        this.summary = value;
    }

    set summary(value) {
        this.#summaryNode.innerHTML = value || _("Please Select");
    }


    handleEvent_slotchange(evt) {
        for(const node of this.querySelectorAll('input')) {
            node.removeEventListener('change', this);
            node.addEventListener('change', this);
            if(node.type === "checkbox") node.previousElementSibling.disabled = !node.checked;
        }
        const node = this.querySelector('input[type="radio"]:checked');
        if(node) this.summary = node.dataset.value || node.value;
    }

    handleEvent(evt) {
        if(evt.type !== 'change') return super.handleEvent(evt);
        const n = evt.currentTarget;
        if(n.type === "radio") {
            this.summary = n.dataset.value || n.value;
        }
        if(n.type === "checkbox") {
            let radio = n.previousElementSibling;
            if((radio.disabled = !n.checked)) {
                if(radio.checked) {
                    radio.checked = false;
                    radio = this.querySelector('input[type="checkbox"]:checked')?.previousElementSibling;
                    if(radio) radio.checked = true;
                }
            }
            else {
                if(!this.querySelector('input[type="radio"]:checked')) n.previousElementSibling.checked = true;
            }
        }
    }

    handleEvent_toggle(evt) {
        if(!evt.target.open) return;
        for(const n of document.getElementsByTagName("qb-select")) if(n !== this) n.open = false;
    }

    set open(value) {
        this.#detailsNode.open = value;
    }

    get open() {
        return this.#detailsNode.open;
    }

    set selected(value) {
        if(value === null) this.querySelectorAll(`input[type="radio"]`).forEach(n=>n.checked = false);
        else this.querySelector(`input[type="radio"][value="${value}"]`).checked = true;
    }

    get selected() {
        return this.querySelector(`input[type="radio"]:checked`)?.value;
    }

    set selection(value) {
        if(!(value instanceof Array)) value = [value];
        for(const n of this.querySelectorAll(`input[type="radio"]`)) {
            n.disabled = !value.includes(n.value);
        }
        for(const n of this.querySelectorAll(`input[type="checkbox"]`)) {
            n.checked = value.includes(n.value);
        }
    }

    get selection() {
        return [...this.querySelectorAll(`input[type="checkbox"]:checked`)].map(n => n.value);
    }

}

QbSelect.register;


