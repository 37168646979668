/* Copyright 2022- Martin Kufner */
const chrome = self.chrome
class RemoteLoggerClass {
    get origin() {
        return new URL(location.origin);
    }

    update(options) {
        if(typeof options !== "object") return;
        if('preventDefault' in options) this.preventDefault = options.preventDefault;
    }

    async parseEvt(event) {
        let error = event.type === 'unhandledrejection' ? event.reason : event.error;

        try {
            // if(evt.handled) return;
            // Object.defineProperty(evt, 'handled', {value: true});
            if(!(error instanceof Error)) return; // debugger;
            const stack = error.stack.split(/\n/).filter(e=>e && !/exception-logger\.js/.test(e));
            if(!stack.length) return;
            const body = Object.slice(error, 'message', 'filename', 'lineno', 'colno', 'error', 'details');
            body.type = event.type;
            body.name = error.constructor.name;
            body.backtrace = stack;
            Object.self_compact(body);
            const errmsg = [event.type, body, body.backtrace];
            this.send('exception', body)
                .then(() => console.warn(...errmsg))
                .catch(e => console.error("Logger cannot send", event, error, body));
        }
        catch(e) {
            console.error(event, error);
        }
    }

    handleEvent(evt) {
        switch(evt.type) {
            case "mousedown":
            case "mousemove":
            case "keydown":
            case "touchmove":
            case "touchend":
            case "touchcancel":
                return this.parseUIEvent(evt);
        }
        if(this.preventDefault !== false) {
            evt.preventDefault();
            evt.stopImmediatePropagation();
            evt.stopPropagation();
        }
        this.parseEvt(evt);
    }

    _send_xmlhttprequest(level, params) {
        return new Promise((resolve, reject) => {
            const url = new URL(`/logger/${level}`, this.origin);
            const xhr = new XMLHttpRequest();
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/json");

            xhr.timeout = 5000; // Set timeout to 5000 ms

            xhr.withCredentials = true; // For 'same-origin' credentials

            xhr.onload = function() {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(xhr.responseText);
                } else {
                    reject(new Error(`Request failed with status ${xhr.status}`));
                }
            };

            xhr.ontimeout = function() {
                reject(new Error("Request timed out"));
            };

            xhr.onerror = function() {
                reject(new Error("Network error"));
            };

            xhr.send(JSON.stringify(params));
        });
    }

    async _send_fetch(level, params) {
        const url = this.origin;
        url.pathname = `/logger/${level}`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(params),
            headers: {"Content-Type": 'application/json'},
            mode: 'cors',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            signal: AbortSignal.timeout(5000)
        });
    }

    async send(level, ...messages) {
        const params = {level, messages: messages};
        if(chrome?.runtime?.getManifest) {
            params.extension_version = chrome.runtime.getManifest().version;
        }
        if(messages[0]?.backtrace) {
            params.backtrace = messages[0]?.backtrace;
            delete messages[0]?.backtrace;
        }
        else {
            const error = new Error();
            if(error?.stack) {
                params.backtrace = error.stack.split(/\n/);
            }
        }
        try {
            // this._send_fetch(level, params);
            this._send_xmlhttprequest(level, params).catch(e=>console.error(params));
        }
        catch(e) {
            console.warn("Logger cannot send", level, ...messages);
        }
    }

    debug(...params) { this.send('debug', ...params) }

    log(...params) { this.send('info', ...params) }

    info(...params) { this.send('info', ...params) }

    warn(...params) { this.send('warn', ...params) }

    error(...params) { this.send('error', ...params) }

    tracker(...params) { this.send('tracker', ...params) }

    #uiEventHandle;
    get start_tracking() {
        if(this.#uiEventHandle) return;
        this.#uiEventHandle = setInterval(() => this.sendUIEvent, 10000);
        self.addEventListener('mousedown', self.RemoteLogger, true);
        self.addEventListener('mousemove', self.RemoteLogger, true);
        self.addEventListener('keydown', self.RemoteLogger, true);
        self.addEventListener('touchmove', self.RemoteLogger, true);
        self.addEventListener('touchend', self.RemoteLogger, true);
        self.addEventListener('touchcancel', self.RemoteLogger, true);
    }

    get stop_tracking() {
        if(!this.#uiEventHandle) return;
        clearInterval(this.#uiEventHandle);
        self.removeEventListener('mousedown', self.RemoteLogger, true);
        self.removeEventListener('mousemove', self.RemoteLogger, true);
        self.removeEventListener('keydown', self.RemoteLogger, true);
        self.removeEventListener('touchmove', self.RemoteLogger, true);
        self.removeEventListener('touchend', self.RemoteLogger, true);
        self.removeEventListener('touchcancel', self.RemoteLogger, true);
    }

    #uiEvent = []

    parseUIEvent(evt) {

        let target = null, currentTarget = null;
        if(evt.target instanceof Node) {
            target = `<${evt.target.nodeName}`
            if(evt.target.id) target += `#${evt.target.id}`;
            if(evt.target.className) target += `.${evt.target.className.replace(" ", ".")}`;
            target += ">"
        }
        if(evt.currentTarget instanceof Node) {
            currentTarget = `<${evt.currentTarget.nodeName}`
            if(evt.currentTarget.id) target += `#${evt.currentTarget.id}`;
            if(evt.currentTarget.className) target += `.${evt.currentTarget.className.replace(" ", ".")}`;
            currentTarget += ">"
        }
        const rv = {
            type: evt.type,
            timestamp: Date.now() / 1000,
            buttons: evt.buttons || null,
            x: evt.x,
            y: evt.y,
            key: evt.key,
            code: evt.code,
            composed: evt.type === "keydown" ? evt.composed : null,
            layerX: evt.layerX,
            layerY: evt.layerY,
            pageX: evt.pageX,
            pageY: evt.pageY,
            movementX: evt.movementX || null,
            movementY: evt.movementY || null,
            screenX: evt.screenX,
            screenY: evt.screenY,
            shiftKey: evt.shiftKey || null,
            ctrlKey: evt.ctrlKey || null,
            metaKey: evt.metaKey || null,
            altKey: evt.altKey || null,
            target, currentTarget
        };
        this.#uiEvent.push(Object.compact(rv));

    }

    get sendUIEvent() {
        const events = this.#uiEvent.splice(0);
        if(!events.length) return;
        this.tracker(...events);
    }
}

if(!self.RemoteLogger) {
    self.RemoteLogger = new RemoteLoggerClass();
    self.addEventListener('error', self.RemoteLogger, true);
    self.addEventListener('unhandledrejection', self.RemoteLogger, true);
}

export const RemoteLogger = function (options) {
    if(options) self.RemoteLogger.update(options);
    return self.RemoteLogger;
}
