
import {QbParticipantPerson} from "./base"
import {DELETE, HEAD, PUT} from "@qb-assets-js/qb-fetch";
export class QbPlayer extends QbParticipantPerson {
    get defaults() {
        return {
            avatar: '😎',
            name: 'Unknown'
        };
    }

    get finalize() {
        this.staff = (this.itemid === self.ME.player_id || this.itemid === self.ME.user_id);
    }


    get #slotNodes() {
        return [...this.parentElement.getElementsByTagName("qb-slot")].filter(n=>n.index !== this.index);
    }

    handleEvent(evt) {
        switch(evt.type) {
            case "dragstart":
                evt.dataTransfer.effectAllowed = "move";
                this.addEventListener('dragend', this, {once: true});
                this.#slotNodes.forEach(n=>{
                    n.addEventListener('dragover', this);
                    n.addEventListener('drop', this);
                    n.addEventListener('dragenter', this);
                    n.addEventListener('dragleave', this);
                });
                return;
            case "dragover":
                evt.preventDefault();
                evt.dataTransfer.dropEffect = "move";
                return;
            case "drop":
                evt.preventDefault();
                const index = evt.target.index,
                    itemid = this.itemid;
                PUT(location.toString().replace(/\/moderate.*$/, `/${index}`), {body: {itemid}}).result({
                    "202": (d) => { this.index = index; }
                });
                return;
            case "dragenter":
                return evt.currentTarget.addClass("dragging");
            case "dragleave":
                return evt.currentTarget.removeClass("dragging");
            case "dragend":
                this.#slotNodes.forEach(n=>{
                    n.removeEventListener('dragover', this);
                    n.removeEventListener('drop', this);
                    n.removeEventListener('dragenter', this);
                    n.removeEventListener('dragleave', this);
                });
                return [...document.getElementsByClassName("dragging")].forEach(n=>n.removeClass("dragging"));
            default:
                super.handleEvent(evt);

        }
    }


    get identifier() {
        return JSON.stringify({
            itemtype: "Player",
            itemid: this.itemid,
            show_id: Show.itemid
        });
    }

    get staffNodes() {
        super.staffNodes;
        this.querySelector("button.leave") || BUTTON({beforeend: this,
                class: "leave admin", aria: {label: "Leave quiz"}, events: [this, {click: "leaveQuiz"}]
            }
        );
    }

    handleEvent_leaveQuiz(evt) {
        confirm(_("Do you want to leave the quiz?")) && DELETE(`${location}/player`);
    }
}
QbPlayer.register;