Object.defineProperties(HTMLElement.prototype, {
    clear: {
        get() {
            this.textContent = "";
            return this;
        }
    },

    addClass: {value(...args) { this.classList.add(...args.filter(n => n)) }},
    setClass: {value(value, ...args) { this.classList[value ? 'add' : 'remove'](...args.filter(n => n)) }},
    hasClass: {value(arg) { return this.classList.contains(arg) }},
    removeClass: {value(...args) { this.classList.remove(...args.filter(n => n)) }},
    toggleClass: {value(...args) { return this.classList.toggle(...args) }},

    toggleDataset: {
        value(prop, value) {
            const ds = this.dataset;
            if(ds[prop] === value) delete ds[prop];
            else ds[prop] = value;
        }
    },


    itemscope: {
        get() {
            if(this.hasAttribute('itemid') || this.hasAttribute('itemtype') || this.hasAttribute('itemref')) this.setAttribute('itemscope', '');
            else this.removeAttribute('itemscope');
            return this.hasAttribute('itemscope');
        }
    },
    itemtype: {
        set(value) {
            if(/^https?:\/\//.test(value)) this.setAttribute('itemtype', value);
            else if(value) this.setAttribute('itemtype', `${location.origin}/schema/${value}`);
            else this.removeAttribute('itemtype');
            this.itemscope;
        },
        get() {
            return this.getAttribute('itemtype')?.replace(/^.*\//, '');
        }
    },
    itemid: {
        set(value) {
            if(value) this.setAttribute('itemid', value);
            else this.removeAttribute('itemid');
            this.itemscope;
        },
        get() { return this.getAttribute('itemid') }
    },
    itemref: {
        set(value) {
            if(value) this.setAttribute('itemref', value);
            else this.removeAttribute('itemref');
            this.itemscope;
        },
        get() { return this.getAttribute('itemid') }
    },
    itemprop: {
        set(value) {
            if(value) this.setAttribute('itemprop', value);
            else this.removeAttribute('itemprop');
        },
        get() {
            return this.getAttribute('itemprop');
        }
    },

    setCustomProperties: {
        value(key_object, value) {
            if(value !== undefined) {
                if(typeof key_object !== "string") throw 'key must be a string if value exists';
                key_object = Object.fromEntries([[key_object, value]]);
            }
            for(let [k, v] of Object.entries(key_object)) {
                k = k.replace(/^-*/, "--");
                if(v instanceof Text) v = `"${v.data}"`;
                if(v || v !== 0) this.style.setProperty(k, v);
                else this.style.removeProperty(k);
            }
        }
    },

    setAttributes: {
        value(attrs) {
            for(const key in attrs) {
                if(!Object.hasOwn(attrs, key)) continue;
                const value = attrs[key];
                if(value === null || value === undefined) this.removeAttribute(key);
                else this.setAttribute(key, value);
            }
        }
    },
    parseJsonData: {
        get() {
            const node = [...this.childNodes].find(n => n && ('jsonData' in n));
            if(!node) return null;
            return node.jsonData;
        }
    },
    dataParse: {
        value(key) {
            if(!("dataParse" in self)) {
                const value = Object.assign({}, ...[...document.head.childNodes].map(n => n.jsonData).filter(n => n));
                Reflect.defineProperty(self, "dataParse", {value, writable: false, configurable: false});
            }
            const value = self.dataParse[key];
            const array = [], objects = [];
            [...this.childNodes].forEach(n => {
                const data = n.jsonData;
                if(data instanceof Array) array.push(...data);
                else if(typeof data === "object") objects.push(data);
            });
            if(key || objects.length) return Object.assign({}, value, array, ...objects);
            else return array;
        }
    },
    update: {
        value(value) {
            console.error(`No method update ${this.nodeName} value`, value);
        }
    },
    initEvents: {
        value(target) {
            const events = this.getAttribute("events").split(/\s+/).map(e => e.split(":"))
            for(const [e, t] of events) {
                this.removeEventListener(e, target);
                if(t) {
                    if(!this.handleEventTargets) this.handleEventTargets = {};
                    this.handleEventTargets[e] = t;
                }
                this.addEventListener(e, target);
            }
        }
    },

});

