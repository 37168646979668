import "@node/emoji-picker-element";
class QbEmojiPicker extends customElements.get("emoji-picker") {
    #onemojiclick

    connectedCallback() {
        if(!this._cmp) {
            super.connectedCallback();
            const sheet = this.shadowRoot.firstElementChild.sheet;
            sheet.insertRule(`slot {
            display: grid;
            grid-template-columns: 1fr;
            margin: var(--emoji-padding);
            // padding-bottom: var(--emoji-padding);
        }`);
            this.shadowRoot.querySelector(".pad-top").after(SLOT());
            this.lastElementChild.style.color = new Color(this.firstElementChild.value).invertBW;
        }
    }


    static get observedAttributes() {
        return super.observedAttributes.concat("onemojiclick");
    }

    set onemojiclick(value) {
        if(typeof value === "string") {
            value = new Function("event", value).bind(this);
        }
        this.#onemojiclick = value;
        this.removeEventListener("emoji-click", this);
        if(value) this.addEventListener("emoji-click", this);
    }

    _set(prop, newValue) {
        switch(prop) {
            case "onemojiclick":
                return this.onemojiclick = newValue;
        }
        super._set(prop, newValue);
    }

    handleEvent(evt) {
        switch(evt.type) {
            case "emoji-click":
                if(!this.#onemojiclick) return;
                Object.defineProperty(evt, "data", {value: evt.detail.unicode});
                return this.#onemojiclick(evt);
        }
    }


}

if(!customElements.get('qb-emoji-picker')) { // if already defined, do nothing (e.g. same script imported twice)
    customElements.define('qb-emoji-picker', QbEmojiPicker);
}

class QbPlayerAvatar extends HTMLElement {
    static observedAttributes = ["name", "color", "avatar"];

    #nameNode
    #colorNode
    #avatarNode
    #avatarField
    #colorText
    constructor() {
        super();
        this.#avatarNode = DIV({tabIndex: 0});
        this.#avatarField = cT('input type="hidden" name="player[avatar]"');
        this.#nameNode = cT('input type="text" name="player[name]" autocomplete="off" _placeholder="Min 3 letters" required="required" pattern=".*[A-Za-z0-9].*[A-Za-z0-9].*[A-Za-z0-9].*"');

        this.#colorNode = cT('input type="color" name="player[color]"');
        this.#colorText = cT('qb-gettext');
        this.#colorText.content = { _: "Choose background color" };
        this.#colorNode.addEventListener('input', this);
    }

    connectedCallback() {
        if(!this.hasChildNodes()) {
            this.append(
                this.#avatarField,
                this.#avatarNode,
                this.#nameNode
            );
            cT("qb-emoji-picker", {beforeend: this}, this.#colorNode, this.#colorText).addEventListener("emoji-click", this);
        }
    }

    handleEvent(evt) {
        if(evt.type === "emoji-click") return this.avatar = evt.detail.unicode;
        switch(evt.currentTarget) {
            case this.#colorNode:
                return this.color = evt.target.value;
        }
    }

    set name(value) { this.#nameNode.value = value }
    get name() { return this.#nameNode.value }

    set avatar(value) { this.#avatarNode.textContent = this.#avatarField.value = value }
    get avatar() { return this.#avatarField.value }

    set color(value) {
        if(!/^#?[a-z0-9]{6}$/.test(value)) value = "#888888";
        this.#avatarNode.style.backgroundColor = this.#colorNode.value = value;
        this.#colorText.style.color = new Color(value).invertBW;
    }
    get color() { return this.#colorNode.value }

    get values() { return {color: this.color, name: this.name, avatar: this.avatar} }

    attributeChangedCallback(attrName, oldValue, newValue) {
        this.__lookupSetter__(attrName)?.call(this, newValue);
    }

}
if(!customElements.get('qb-player-avatar')) { // if already defined, do nothing (e.g. same script imported twice)
    customElements.define('qb-player-avatar', QbPlayerAvatar);
}