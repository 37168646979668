/* Copyright 2022- Martin Kufner */
Object.definePropertiesUnlessDefined(Array.prototype, {
    uniq: {
        value() {return this.filter((value, index, self) => self.indexOf(value) === index)}
    },
    inGroupsOf: {
        value(n) {
            return this.reduce((p, c, i) => {
                i % n ? p[0].push(c) : p.unshift([c]);
                return p
            }, []).reverse()
        }
    },
    tally: {
        value() {
            return this.reduce((p, c) => {
                if(p.hasOwnProperty(c)) p[c] += 1;
                else p[c] = 1;
                return p;
            }, {})
        }
    },

    groupBy: {
        value(fn) {
            return this.reduce((p, c) => {
                const rv = fn(c);
                if(p.hasOwnProperty(rv)) p[rv].push(c);
                else p[rv] = [c];
                return p;
            }, {})
        }
    },
    at: {
        value(index) {
            return this[index < 0 ? this.length - index : index];
        }
     },
    max: {
        get() {
            return Math.max(...this);
        }
    },
    min: {
        get() {
            return Math.min(...this);
        }
    }
});

