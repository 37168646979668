import {QbElement} from "./qb-element.js"
import {Big} from "../js-extensions/big";
export class QbDecimal extends QbElement {
    static shadow = {mode: 'open', sheets: ["qb-elements"]}
    static template = '<div part="int"></div><div part="frac"></div><div part="unit"></div><div part="scale"></div><slot part="input"></slot>';
    #decimal
    #intNode
    #fracNode
    #unitNode
    #scaleNode
    #slotNode
    #observer
    constructor() {
        super();
        [this.#intNode, this.#fracNode, this.#unitNode, this.#scaleNode, this.#slotNode] = this.shadowRoot.children;
    }

    connectedCallback() {
        this.#changes(this.childNodes[0]);
        this.#observer = new MutationObserver((records, observer)=>this.#changes(records[0].addedNodes[0]));
        this.#observer.observe(this, { characterData: true, childList: true });
    }

    handleEvent(evt) {
        this.value = evt.target.value;
    }

    #changes(node) {
        if(!node) return;
        if(node instanceof Text) this.#value = node.data;
        else if(node instanceof HTMLInputElement) {
            this.#value = node.value;
            node.addEventListener("change", this);
        }
    }

    get ladder() {
        return this.#decimal.ladder;
    }

    get isPeriodic() {
        return this.#decimal.isPeriodic;
    }

    get value() {
        if(this.firstChild instanceof HTMLInputElement) return this.firstChild.value;
        else return this.textContent || null;
    }


    get decimal() {
        return new Decimal(this.value) || null;
    }

    set value(value) {
        try {
            if(typeof value === "string") value = value.replace(",",".");
            value = new Decimal(value);
        }
        catch(e) {
            value = null;
        }
        if(this.firstChild instanceof HTMLInputElement) this.firstChild.value = value?.toFixed() || "";
        else this.textContent = value?.toFixed() || "";
    }

    set #value(value) {
        try {
            if(typeof value === "string") value = value.replace(",",".");
            value = new Decimal(value);
        }
        catch(e) {
            return this.#decimal = null;
        }
        if(this.#decimal === value) return;
        this.#decimal = value;
        const {sign, unit, scale, fixed} = this.ladder;
        const [int, frac] = fixed;
        const html_int = int.reverse.replace(/\d{1,3}/g, '$& ').reverse.trim().split(" ").map(f => `<span>${f}</span>`);
        const html_frac = [];
        if(frac) frac.replace(/\d{1,3}/g, '$& ').trim().split(" ").forEach(f =>{if(f) html_frac.push(`<span>${f}</span>`)});
        const periodic = this.isPeriodic;
        if(periodic) {
            const i = Math.floor(Math.max(0, Big.DP - periodic) / 3);
            html_frac[i] = html_frac[i].replace(/>/, ' class="periodic">')
        }
        this.#intNode.setAttribute("sign", sign ? "-" : "");
        this.#intNode.innerHTML = html_int.join("")
        this.#fracNode.innerHTML = html_frac.join("")
        this.#unitNode.textContent = unit.replace("-", "");
        this.#scaleNode = scale;

    }
    

}
QbDecimal.register;