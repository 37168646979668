const debug = true;
import {QbFetch} from './qb-fetch'

const FETCH = function (url, ...options) {
    options.push({debug});
    return new QbFetch(url, ...options);
}
const GET = function (url, ...options) { return FETCH(url, ...options, {method: "GET"}) }
const POST = function (url, ...options) { return FETCH(url, ...options, {method: "POST"}) }
const PUT = function (url, ...options) { return FETCH(url, ...options, {method: "PUT"}) }
const PATCH = function (url, ...options) { return FETCH(url, ...options, {method: "PATCH"}) }
const HEAD = function (url, ...options) { return FETCH(url, ...options, {method: "HEAD"}) }
const DELETE = function (url, ...options) { return FETCH(url, ...options, {method: "DELETE"}) }

Object.defineProperties(FETCH, {
    GET: {value: GET},
    POST: {value: POST},
    PUT: {value: PUT},
    PATCH: {value: PATCH},
    HEAD: {value: HEAD},
    DELETE: {value: DELETE}
});

export {
    QbFetch,
    FETCH,
    GET,
    POST,
    PUT,
    PATCH,
    HEAD,
    DELETE
}