import {QbElement} from "./qb-element.js"

class QbCode extends QbElement {
    static observedAttributes = ["size", "value", "name", "disabled"]
    static shadow = {mode: 'open'}

    constructor() {
        super();
        this.contentRoot.append(SLOT());
    }

    #value
    #name = "code"

    get name() {
        return this.#name;
    }

    set name(value) {
        this.#name = value;
    }

    $name(value) {
        this.#name = value;
        [...this.children].forEach(n => n.name = `${this.#name}[]`);
    }


    set value(value) { this.$value(value)}

    set disabled(value) {
        [...this.children].forEach(n=>n.disabled = value);
    }

    focus() {
        if(this.matches(':focus-within')) return;
        this.firstElementChild.focus();
    }

    $size(value) {
        [...this.children].forEach(n => n.remove());
        for(let i = 0; i < value; i++) this.append(
            cT('input type="text" autocomplete="off" required="true" maxLength="1" size="1"', {
                name: `${this.#name}[]`,
                style: 'text-align:center',
                events: [this, "input", "beforeinput"]
            })
        );
    }


    handleEvent_beforeinput(evt) {
        if(evt.inputType === 'insertFromPaste') {
            evt.preventDefault();
            const m = evt.data.match(/[A-Za-z0-9]+$/);
            if(!m) return;
            const code = m[0];
            [...this.querySelectorAll('[type="text"]')].forEach((n,i) => n.value=code[i] || "");
            this.contentRoot.lastElementChild.focus();
            return;
        }
        if(evt.inputType === 'deleteContentBackward' && !evt.currentTarget.value) {
            evt.currentTarget.previousElementSibling?.focus();
        }

        evt.currentTarget.value = '';
    }

    handleEvent_input(evt) {
        this.#value = this.value;
        if(evt.inputType === 'deleteContentBackward') return;
        let next = evt.currentTarget.nextElementSibling;
        if(!next && this.form) {
            const elements = [...this.form.elements];
            next = elements[elements.indexOf(this) + 1];
        }
        if(next) next.focus();
        else evt.currentTarget.blur();
    }
}

QbCode.register;