import {QbElement} from "@qb-assets-js/qb-elements/qb-element";
import {POST, PATCH, DELETE} from "../qb3-element";

export class QbParticipantBase extends QbElement {
    static observedAttributes = ['itemid'];
    static shadow = {mode: 'open', sheets: ["qb-elements"]};
    static template = '<div part="avatar" class="avatar"></div><div part="name" class="name"></div><slot part="slot"></slot>';

    connectedCallback() {
        QbEntity.get(this);
        super.connectedCallback();
    }

    #property(name) {
        return getComputedStyle(this).getPropertyValue(`--slot-${name}`);
    }

    get name() { return this.#property('name')?.replace(/^"|"$/g, "")?.replace(/\\"/g, '"') };
    get color() { return this.#property('color') }
    get avatar() { return this.#property('avatar')?.replace(/^"|"$/g, "") }

    set name(name) { QbEntity.name(this.itemid, name) }
    set color(color) { QbEntity.color(this.itemid, color) }
    set avatar(avatar) { QbEntity.avatar(this.itemid, avatar) }

    get index() { return parseInt(this.#property('index-num')) }
    set index(index) { QbEntity.index(this.itemid, index) }

    set member_only(_) {}

    get member_only() {}

    #staff
    get staff() { return this.hasClass("admin") }
    set staff(value) {
        this.#staff = !!value;
        this.setClass(value, "admin");
        if(value) this.staffNodes;
        else this.querySelectorAll(".admin").forEach(n=>n.remove());
    }

    get staffNodes() {
        this.querySelector("button.edit") || BUTTON({
            beforeend: this,
            class: "edit admin",
            events: [this, {click: "toggleEdit"}]
        }, "⚙️");
    }

    set chat(value) { this.setClass(value, "chat") }

    get chat() { this.hasClass("chat") }

    handleEvent_toggleEdit(evt) {
        const target = evt.target;
        const node = target.parentElement.querySelector(".editNode");
        if(node) return node.remove();
        const edit = this.editNode;
        if(edit) target.after(edit);
        edit.focus();
    }


    set current_index(_) {}
}


export class QbParticipantPerson extends QbParticipantBase {
    get selector() {
        return `[itemid="${this.itemid}"], [id$="_${this.itemid}"]`;
    }

    get editNode() {
        const node = cT("qb-player-avatar", {
            class: "editNode", name: this.name, color: this.color, avatar: this.avatar
        });
        node.addEventListener("focusout", e => {
            if(this.contains(e.relatedTarget)) return;
            const values = node.values;
            node.remove();
            let changes;
            if(this.name !== values.name && (changes = true)) this.name = values.name;
            if(this.color !== values.color && (changes = true)) this.color = values.color;
            if(this.avatar !== values.avatar && (changes = true)) this.avatar = values.avatar;
            if(changes) {
                this.update(values);
                PATCH("/avatar", {body: {avatar: values}});
            }
        })
        return node;
    }
    set staff(value) {
        super.staff = value;
    }
    get staff() { return this.hasClass("admin") }


}