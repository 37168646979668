document.adoptStyleSheets("qb-mixin");

class QbMixin extends HTMLElement {
    static observedAttributes = ["key"];

    set key(key) {
        this.setAttribute("key", key);
    }

    get key() { return this.getAttribute("key") }

    get #styleSheet() {
        return AdoptableStyleSheet.sheet("qb-mixin");
    }

    attributeChangedCallback(name, oldValue, key) {
        if(name === "key" && key) this.#rule(key);
    }

    #rule(key) {
        let content;
        const m = key.match(/^(counter|increment)-([a-z][a-z0-9_-]+)\s*(\S+)?/);
        if(!m) content = `content: var(--mixin-${key});`;
        else {
            let name = m[2], style = m[3];
            if(style) {
                if(!/^(none|disc|circle|square|decimal|cjk-decimal|decimal-leading-zero|lower-roman|upper-roman|lower-greek|lower-alphalower-latin|upper-alphaupper-latin|arabic-indic-moz-arabic-indic|armenian|bengali-moz-bengali|cambodian\/khmer|cjk-earthly-branch-moz-cjk-earthly-branch|cjk-heavenly-stem-moz-cjk-heavenly-stem|cjk-ideographic|devanagari-moz-devanagari|ethiopic-numeric|georgian|gujarati-moz-gujarati|gurmukhi-moz-gurmukhi|hebrew|hiragana|hiragana-iroha|japanese-formal|japanese-informal|kannada-moz-kannada|katakana|katakana-iroha|korean-hangul-formal|korean-hanja-formal|korean-hanja-informal|lao-moz-lao|lower-armenian|malayalam-moz-malayalam|mongolian|myanmar-moz-myanmar|oriya-moz-oriya|persian-moz-persian|simp-chinese-formal|simp-chinese-informal|tamil-moz-tamil|telugu-moz-telugu|thai-moz-thai|tibetan|trad-chinese-formal|trad-chinese-informal|upper-armenian)$/.test(style)) style = "numeric";
            }
            else style = `var(--counter-${name}-style, numeric)`;
            // content = `content: counter(${name}, ${style});`;
            content = `content: var(--counter-${name}, counter(${name}, ${style}));`;
            if(m[1] === "increment") content += `counter-increment: var(--counter-increment) var(--counter-${name}-increment, 1);`
        }
        this.#styleSheet.addOrReplaceRule(`qb-mixin[key="${key}"]::before { ${content} }`);
    }
}

customElements.register(QbMixin);

