class Color {
    #hex
    #r
    #g
    #b
    constructor(hex, b, g) {
        if (hex.indexOf('#') === 0) hex = hex.slice(1);
        this.#hex = hex;
        if (hex.length === 3) hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        if (hex.length !== 6) throw new Error(`Invalid HEX color: '${this.#hex}'`);
        this.#r = parseInt(hex.slice(0, 2), 16);
        this.#g = parseInt(hex.slice(2, 4), 16);
        this.#b = parseInt(hex.slice(4, 6), 16);
    }

    get hex() { return this.#hex }
    get r() { return this.#r }
    get b() { return this.#b }
    get g() { return this.#g }
    get invertBW() {
        return (this.#r * 0.299 + this.#g * 0.587 + this.#b * 0.114) > 186 ? '#000000' : '#FFFFFF';
    }
    get invert() {
        // invert color components
        const r = (255 - this.#r).toString(16).padStart(2,'0'),
        g = (255 - this.#g).toString(16).padStart(2,'0'),
        b = (255 - this.#b).toString(16).padStart(2,'0');
        // pad each with zeros and return
        return `#${r}${g}${b}`;
    }
}
self.Color = Color