import Jed from '@node/jed'; // https://messageformat.github.io/Jed/
let i18n;


Object.defineProperties(window, {
    i18n: {
        set(lang) {
            if(document.lang !== lang) document.lang = lang;
        },
        get() {
            return i18n
        }
    },

    gettext: {
        value(key) {
            try {
                return i18n?.gettext(key);
            }
            catch(e) {
                return key;
            }
        }
    },
    dgettext: {
        value(domain, key) {
            try {
                return i18n?.dgettext(domain, key);
            }
            catch(e) {
                return key;
            }
        }
    },
    dcgettext: {
        value(domain, key, category) {
            try {
                return i18n?.dcgettext(domain, key, category);
            }
            catch(e) {
                return key;
            }
        }
    },
    ngettext: {
        value(singular_key, plural_key, value) {
            try {
                return i18n?.ngettext(singular_key, plural_key, value);
            }
            catch(e) {
                return value ? plural_key : singular_key;
            }
        }
    },
    dngettext: {
        value(domain, singular_ley, plural_key, value) {
            try {
                return i18n?.dngettext(domain, singular_key, plural_key, value);
            }
            catch(e) {
                return value ? plural_key : singular_key;
            }
        }
    },
    dcngettext: {
        value(domain, singular_key, plural_key, value, category) {
            try {
                return i18n?.dcngettext(domain, singular_key, plural_key, value, category);
            }
            catch(e) {
                return value ? plural_key : singular_key;
            }
        }
    },
    pgettext: {
        value(context, key) {
            try {
                return i18n?.pgettext(context, key);
            }
            catch(e) {
                return key;
            }
        }
    },
    dpgettext: {
        value(domain, context, key) {
            try {
                return i18n?.dpgettext(domain, context, key);
            }
            catch(e) {
                return key;
            }
        }
    },
    npgettext: {
        value(context, singular_key, plural_key, value) {
            try {
                return i18n?.npgettext(context, singular_key, plural_key, value);
            }
            catch(e) {
                return value ? plural_key : singular_key;
            }
        }
    },
    dnpgettext: {
        value(domain, context, singular_key, plural_key, value) {
            try {
                return i18n?.dnpgettext(domain, context, singular_key, plural_key, value);
            }
            catch(e) {
                return value ? plural_key : singular_key;
            }
        }
    },
    dcnpgettext: {
        value(domain, context, singular_key, plural_key, value, category) {
            try {
                return i18n?.dcnpgettext(domain, context, singular_key, plural_key, value, category);
            }
            catch(e) {
                return value ? plural_key : singular_key;
            }
        }
    },

    _: {
        value(key, mixin) {
            if(key instanceof Array) key = key[0];
            const text = window.gettext(key) || key;
            return mixin ? String.mixin(text, mixin) : text;
        }
    },
    n_: {
        value(skey, pkey, val, mixin) {
            const text = window.ngettext(skey, pkey, val) || (val === 1 ? skey : pkey);
            return mixin ? String.mixin(text, mixin) : text;
        }
    },
    p_: {
        value(context, key) {
            const text = window.pgettext(key) || key;
            return mixin ? String.mixin(text, mixin) : text;
        }
    }
});


const updateGettextTags = () => [...document.getElementsByTagName("qb-gettext")].forEach(n => n.init);

const _title_update = m => {
    m.forEach(m => {
        const {target} = m;
        let text = target.getAttribute("_title");
        if(text !== null) {
            if(!text) target.removeAttribute("title");
            else target.setAttribute("title", _(text));
        }
        text = target.getAttribute("_placeholder");
        if(text !== null) {
            if(!text) target.removeAttribute("placeholder");
            else target.setAttribute("placeholder", _(text));
        }
        text = target.getAttribute("_content");
        if(text !== null) {
            if(!text) target.textContent = text ? _(text) : "";
        }
    });
}
new MutationObserver(_title_update).observe(document.body, {
    attributeFilter: ["_title", "_content", "_placeholder"],
    subtree: true
})
const updateTitle = () => document.querySelectorAll("[_title], [_content], [_placeholder]").forEach(target => _title_update([{target}]));
document.stateInteractive.then(() => updateTitle());

const loadLocale = async function () {
    const locale = document.documentElement.lang;
    if(!locale || locale === i18n?.lang) return;
    const url = new URL(location.origin);
    url.pathname = `/locale/${locale}.json`;
    try {
        const data = await fetch(url).then(r => r.json());
        i18n = new Jed(data)
        Object.defineProperties(i18n, {
            repo: {
                get() {
                    return this.options
                }
            },
            head: {
                get() {
                    const domain = this.repo.domain;
                    return this.repo.locale_data[domain][""];
                }
            },
            lang: {
                get() {
                    return this.head?.lang;
                }
            }
        });
        updateGettextTags();
        updateTitle();
        return document.lang = i18n?.lang;
    }
    catch(e) {
    }
}


new MutationObserver(loadLocale).observe(document.documentElement, {attributeFilter: ["lang"]})
addEventListener("languagechange", updateGettextTags);
if(document.lang) loadLocale();
else document.lang = document.preferredLang[0] || "en";

Object.defineProperties(String, {
    mixin: {
        value(text, mixin) {
            if(!mixin) return text;
            const re = new RegExp(`%\\{(${Object.keys(mixin).join(')\\}|%\\{(')})\\}`, 'g');
            return text.replace(re, m => {
                const v = mixin[m.slice(2, -1)];
                return v === undefined ? m : v;
            });
        }
    }
})




