/* Copyright 2022- Martin Kufner */

import {QbMessage} from './qb-message'
import {QbElement} from '@qb-assets-js/qb-elements/qb-element';

import "@node/emoji-picker-element";


import emojiDataUrl from '@node/emoji-picker-element-data/en/emojibase/data.json?url';

self.emojiDataUrl = emojiDataUrl;
const chatConfettiRegex = /@(\p{Extended_Pictographic}|\p{Emoji_Presentation}|\p{Emoji}\uFE0F)/gu;


export class QbChat extends QbElement {
    static shadow = {mode: 'open', sheets: ["qb-elements"]};
    #input
    #selection
    #scrollContainer

    constructor() {
        super();
        this.contentRoot.append(
            DIV({part: "button"},
                BUTTON({class: "open", events: [this, {click: "toggle"}]}),
                cT("emoji-picker", {
                    name: 'emojipick',
                    attributes: {"data-source": emojiDataUrl},
                    events: [this, 'emoji-click']
                }),
                SLOT({name: "input", part: "input"}),
                BUTTON("😀", {class: 'emojitoggle', events: [this, {click: 'emojitoggle'}]}),
            ),
            this.#scrollContainer = DIV({part: "scroll"}, SLOT({part: "slot"}))
        );
        this.contentRoot.adoptStyleSheets("slots");

        this.elementSelection = true;

        document.addEventListener("selectionchange", this, {passive: true});
        self.QbChat = this;
    }

    get init() {
        this.#input = ARTICLE({contentEditable: true, slot: "input", afterbegin: this, events: [this, 'input', 'beforeinput', 'keydown']}, BR());
    }

    set open(value) {
        this.contentRoot.querySelector("details").open = value;
    }

    get open() { return this.contentRoot.querySelector("details").open };

    focus() {
        this.open = true;
        this.elementSelection.focus(this.#input);
        return this;
    }

    handleEvent_selectionchange(evt) {
        const {focusNode, focusOffset, anchorNode, anchorOffset, type, isCollapsed} = getSelection();
        if(this.#input.contains(focusNode)) this.#selection = {
            focusNode,
            focusOffset,
            anchorNode,
            anchorOffset,
            type,
            isCollapsed
        };
    }

    handleEvent_toggle(evt) {
        evt.stopImmediatePropagation();
        if(this.#sendMessage) return;
        this.removeClass('new-message');
        if(this.toggleClass('open')) {
            evt.currentTarget.parentElement.addClass('open');
            document.addEventListener('selectionchange', this);
        }
        else {
            evt.currentTarget.parentElement.removeClass('open');
            document.removeEventListener('selectionchange', this);
        }
    }

    handleEvent_emojitoggle(e) {
        const n = this.shadowRoot.querySelector('emoji-picker')
        if(e === false) n.removeClass('open');
        else if(e === true) n.addClass('open');
        else n.toggleClass('open');
    }

    get message_id() {
        const message_id = this.#input.itemid;
        if(message_id) return message_id;
        return this.message_id = `${Date.packed}_${this.itemid}`;
    }

    set message_id(value) {
        this.#input.itemid = value;
    }

    handleEvent_keydown(evt) {
        if(evt.key.startsWith('Esc')) this.reset;
    }

    handleEvent_beforeinput(evt) {
        let data;
        switch(evt.inputType) {
            case "insertFromDrop":
            case "insertFromPaste":
                data = evt.dataTransfer.getData("text/plain");
                // console.log(evt.currentTarget, data);
                evt.preventDefault();
                this.insert(data);
                return
            case "insertParagraph":
                evt.preventDefault();
                return this.#sendMessage;
        }
    }

    get #sendMessage() {
        let itemtype = "Chat";
        if(chatConfettiRegex.test(this.message)) itemtype = "Reaction"
        const data = {
            message: this.message,
            itemid: this.message_id,
            itemtype
        };
        if(this.#input.id) data.reference = {itemid: this.#input.id, text: this.#input.dataset.reference};
        this.#input.removeClass("reply-message");
        this.#input.removeAttribute("id");
        delete this.#input.dataset.reference;

        if(!data.message) return false;
        this.receive(data);
        this.dispatch("qb-chat", data);
        this.reset;
        this.handleEvent_emojitoggle(false);
        return true;
    }

    handleEvent_input(evt) {
        const {focusNode, focusOffset, anchorNode, anchorOffset, type, isCollapsed} = getSelection();
        this.#selection = {focusNode, focusOffset, anchorNode, anchorOffset, type, isCollapsed};
    }

    handleEvent_emojiClick(e) {
        this.insert(e.detail.unicode);
    }


    get reset() {
        this.#input.innerHTML = "<br/>";
        this.message_id = null;
        this.#input.removeClass("edit-message", "reply-message");
    }

    edit(text, message) {
        this.reset;
        this.#replace(text);
        this.#input.addClass("edit-message");
        delete this.#input.dataset.reply;
        this.#input.id = null;
        this.message_id = message?.id?.substring(1) || `${Date.packed}_${this.itemid}`;
    }

    reply(text, message) {
        this.reset;
        this.message_id = `${Date.packed}_${this.itemid}`;
        this.#input.id = message?.id?.substring(1);
        this.#input.addClass("reply-message");
        this.#input.dataset.reference = text;
        const selection = getSelection();
        selection.setPosition(this.#input);
        selection.collapseToEnd();
    }

    #replace(text) {
        this.#input.textContent = text || "";
        this.#input.append(BR());
        const selection = getSelection();
        selection.setPosition(this.#input);
        selection.collapseToEnd();
    }

    insert(value) {
        const {anchorNode, anchorOffset, focusNode, focusOffset, type, isCollapsed} = this.#selection || {},
            selection = getSelection();
        if(typeof value === "string") value = new Text(value);
        let frag = (value instanceof DocumentFragment) ? value : document.createDocumentFragment();
        if(value instanceof Array) frag.append(...value);
        else if(!(value instanceof DocumentFragment)) frag.append(value);

        if(!focusNode || type === "None") {
            const lastNode = this.#input.lastChild,
                offset = this.#input.childNodes.length - lastNode.nodeName === "BR" ? 1 : 0;
            selection.setPosition(this.#input, offset);
        }
        else {
            selection.setBaseAndExtent(anchorNode, anchorOffset, focusNode, focusOffset);
        }
        const range = selection.getRangeAt(0);
        range.deleteContents();
        range.insertNode(frag);
        selection.setPosition(range.endContainer, range.endOffset);
        return this;
    }

    get message() {
        return this.#input.textContent.replace(/^\s+|\s+$/g, '');
    }

    set message(value) {
        return this.#input.textContent = value.replace(/^\s+|\s+$/g, '');
    }

    connectedCallback() {
        // this.getElementsByTagName('qb-chat-editor').length ||
        // this.prepend(Object.assign(document.createElement("qb-chat-editor"), {className: "bottom"}));
        this.dataParse("chat").messages?.forEach(args => {
            const [itemtype, ...message] = args;
            this.receive(message, itemtype);
        });
    }

    initialize(messages, options={}) {
        if(!(messages instanceof Array)) messages = [messages];
        let last_msg;
        for(const message of messages) {
            let msg;
            switch(typeof message) {
                case "object":
                    msg = this.#receiveMessage(message);
                    break;
                case "string":
                    msg = this.#receiveNotice(message, options);
                    break;
            }
            if(msg) last_msg = msg;
        }
        return last_msg;
    }

    receive(messages, options = {}) {
        if(messages instanceof Array) return messages.forEach(m=>this.receive(m, options));
        if(messages?.itemtype === "Reaction") {
            if(typeof Confetti !== undefined) return Confetti(50, {
                message: messages.message.replace(chatConfettiRegex, "$1")});
        }
        const last_msg = this.initialize(messages, options);
        if(!last_msg) return;
        const atEnd = this.#scrollContainer.getBoundingClientRect().height + this.#scrollContainer.scrollTop === this.#scrollContainer.scrollHeight;
        if(atEnd) last_msg.scrollIntoView({block: 'end' });
        this.addClass("new-message");
    }

    #receiveMessage({itemid, itemtype, message, remove, ...other}) {
        const existingMessageNode = this.querySelector(`#M${itemid}`);
        if(remove) {
            existingMessageNode?.remove();
            return;
        }
        let messageNode;
        if(existingMessageNode) messageNode = existingMessageNode.exchange(messageNode);
        if(!messageNode) {
            messageNode = QbMessage({
                itemtype, itemid,
                class: "qb-message hover",
                tabindex: 0,
                beforeend: this
            });
        }
        messageNode.update({message, ...other});
        return messageNode;
    }

    #receiveNotice(message, options = {}) {
        let {itemtype = "Notice", itemid, remove} = options;
        message = message.toString();
        if(!itemid) itemid = `${itemtype}_${message.hashCode || "0"}`;
        this.#receiveMessage({itemid, itemtype, message, remove});
    }

}

customElements.register(QbChat);


["message", "notice", "warn", "exception", "error"].forEach(type => {
    Reflect.defineProperty(self, type, {
        value(message, options) {
            self.QbChat.receive(message, Object.assign({itemtype: type.replace(/^(.)/, m => m.toUpperCase())}, options));
        }
    });
})
