Object.defineProperties(Number, {
    pack: {
        value(num, chars=62) {
            if(typeof chars === 'number') chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_'.substring(0, 62);
            if(!this) return chars[0];
            const length = chars.length;
            let encoded = '';
            while (num > 0) {
                let remainder = num % length;
                encoded = chars[remainder] + encoded;
                num = Math.floor(num / length);
            }
            return encoded;
        }
    }
})
Object.defineProperties(Number.prototype, {
    pack: {
        value(chars) {
            if(!this) return chars[0];
            const length = chars.length;
            let encoded = '', num = this;
            while (num > 0) {
                let remainder = num % length;
                encoded = chars[remainder] + encoded;
                num = Math.floor(num / length);
            }
            return encoded;
        }
    },
   pack62: {
       get() {
           return this.pack('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
       }
   },
    precision: {
        value(precision=0) {
            return Math.precision(this, precision);
        }
    }
});