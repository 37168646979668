import pell, {exec} from './pell'
import {QbElement} from "@qb-assets-js/qb-elements/qb-element"

const getLink = () => {
    let focusNode = getSelection().focusNode;
    if(focusNode instanceof Text) return focusNode.parentElement.closest('a');
    else return focusNode.closest('a');
}
const getHref = focusNode => {
    try {
        return new URL(window.prompt('URL', focusNode?.href || "")).toString();
    }
    catch(e) {
    }
}

class QbGettextEdit extends QbElement {
    static nodeName = 'qb-gettext-edit'
   static shadow = { mode: 'open', sheets: ["qb-elements"] };

    connectedCallback() {
        this.lang = this.parentNode.lang;
        this.editorNode.innerHTML = this.gettextNode.content[this.lang] || "";
        this.applySelection;
        this.gettextNode.edit = this.lang;
    }


    get gettextNode() {
        return this.cache('gettextNode', this.parentElement.parentNode.host);
    }

    get inputOptions() {
        const form = this.gettextNode.form ? document.getElementById(this.gettextNode.form) : this.closest('form'),
            name = `${this.gettextNode.name}[${this.lang}]`;
        if(!form || !name) return;
        return {type: 'hidden', form, name}
    }

    get input() {
        const options = this.inputOptions;
        if(!options) return {};
        const form = options.form;
        delete options.form;
        const input = form.elements[options.name] || form.insertAdjacentElement('beforeend', INPUT(options));
        input.value = this.gettextNode.content[this.lang] || "";
        return input;
    }

    change(html) {
    }

    handleEvent_focusout(evt) {
        // console.log(evt.currentTarget, evt.relatedTarget, document.activeElement, this.contentRoot.activeElement);
        if(this.contentRoot.contains(evt.relatedTarget) || this.contentRoot.contains(this.contentRoot.activeElement)) return;
        setTimeout(() => this.remove(), 10);
    }

    _remove() {
        super.remove();
    }

    remove() {
        this.gettextNode.edit = false;
        const value = this.gettextNode.language(this.lang, this.editorNode.innerHTML);
        this.input.value = value || "";
        this._remove();
    }

    get actions() {
        return [
            {
                name: "bold",
                classList: ['inline']
            },
            {
                name: "italic",
                classList: ['inline']
            },
            {
                name: "underline",
                classList: ['inline']
            },
            {
                name: "strikethrough",
                classList: ['inline']
            },
            {
                name: "header",
                icon: '<b>H</b>',
                title: 'Header',
                classList: ['block'],
                result: () => exec('formatBlock', '<header>')
            },
            {
                name: "paragraph",
                classList: ['block'],
                result: () => pell.exec('formatBlock', '<div>')
            },
            {
                name: "olist",
                classList: ['block']
            },
            {
                name: "ulist",
                classList: ['block']
            },
            {
                name: "line",
                classList: ['block']
            },
            {
                name: "link",
                icon: "⚭",
                classList: ['inline'],
                result: () => {
                    const node = getLink(),
                        href = getHref(node);

                    if(node) {
                        if(href) node.href = href;
                        else node.replaceWith(...node.childNodes);
                    }
                    else if(href) {
                        pell.exec('createLink', href);
                        getLink().target = "external";
                    }
                }
            },
            // "image"
        ];
    }

    get editorNode() {
        let actions = this.actions;
        pell.init({
            element: this.contentRoot,
            onChange: () => {},
            // defaultParagraphSeparator: 'div',
            actions

            // classes<Array[string]> (optional)
            // Choose your custom class names
            // classes: {
            //     actionbar: 'pell-actionbar',
            //     button: 'pell-button',
            //     content: 'pell-content',
            //     selected: 'pell-button-selected'
            // }
        });
        const lang = this.lang,
            langs = langNodeOptions({except: lang}),
            menu = this.contentRoot.firstElementChild;
        menu.append(
            // BUTTON("✚", {
            //     className: 'inline',
            //     tabIndex: -1,
            //     attributes: {_title: "Add alternative"},
            //     events: [this, {click: 'alternative'}]
            // }),
            DETAILS({className: 'block'},
                SUMMARY({
                    className: 'flag',
                    attributes: {_title: `Language ${lang}`}
                }, getFlagEmoji(lang, '🏳️‍')),
                DIV(
                    ...langs.map(o => BUTTON({
                        className: 'flag',
                        type: "button",
                        tabIndex: -1,
                        attributes: {_title: "Translate to"},
                        lang: o[0].lang,
                        events: [this, {click: 'translate'}]
                    }, o.slice(0, 2)))
                )
            )
        );
        menu.addEventListener("focusout", () => setTimeout(()=>menu.querySelector("details").open = false, 200));
        const node = this.contentRoot.lastElementChild;
        node.addEventListener("focusout", evt => this.handleEvent_focusout(evt));
        node.addEventListener("paste", evt => this.handleEvent_paste(evt));
        return this.cache('editorNode', node);
    }

    handleEvent_translate(evt) {
        const options = this.inputOptions;
        if(!options) return {};
        const lang = evt.currentTarget.lang,
            form = options.form;
        if(this.parentElement.parentNode.querySelector(`span[lang="${lang}"]`) && !confirm(`Overwrite ${lang}?`)) return;
        delete options.form;
        options.name = options.name.replace(/]$/, `>${lang}]`);
        const input = form.elements[options.name] || form.insertAdjacentElement('beforeend', INPUT(options));
        input.value = this.gettextNode.content[this.lang] || "";
    }

    handleEvent_alternative(evt) {
        console.log("alternative", evt);
    }


    handleEvent_paste(evt) {
        evt.preventDefault();
        let data; // = evt.clipboardData.getData("text/html");
        data = evt.clipboardData.getData("text/plain");
        if(data) {
            return document.execCommand('insertText', false, data);
        }
        // else if(formats.contains("text/html")) {
        //
        // }
        // console.log(text);
    }

    get applySelection() {
        const selection = getSelection(),
            starts = [],
            ends = [];
        for(let node = selection.anchorNode; node.parentElement !== null;) {
            starts.unshift([...node.parentElement.childNodes].indexOf(node));
            node = node.parentElement;
        }
        for(let node = selection.focusNode; node.parentElement !== null;) {
            ends.unshift([...node.parentElement.childNodes].indexOf(node));
            node = node.parentElement;
        }
        const editorNode = this.editorNode,
            anchorNode = starts.reduce((p, c) => p.childNodes[c] || p, editorNode),
            focusNode = ends.reduce((p, c) => p.childNodes[c] || p, editorNode);
        try {
            selection.setBaseAndExtent(anchorNode, selection.anchorOffset, focusNode, selection.focusOffset);
        }
        catch(e) {
            selection.setBaseAndExtent(anchorNode, 0, focusNode, 0);
        }
        return selection;
    }
}

QbGettextEdit.register;