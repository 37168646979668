Object.defineProperties(DocumentFragment.prototype, {
    toString: {
        value() {
            return [...this.children].map(n=>n.textContent).join('');
        }
    }
});
Object.defineProperties(HTMLCollection.prototype, {
    toString: {
        value() {
            return [...this].map(n=>n.textContent).join('');
        }
    }
});