Object.defineProperties(document, {
    lang: {
        get() {
            return this.documentElement.lang;
        },
        set(lang) {
            this.documentElement.setAttribute('lang', lang);
        }
    },
    preferredLang: {
        get() {
            return [document.lang, ...navigator.languages.filter(n => n).map(n => n.slice(0, 2)), "en"].uniq()
        }
    },
    languageInfo: {
        value: {
            xx: {flag: "🏳️‍"},
            ca: {flag: "🇪🇸"},
            ar: {flag: "🇦🇪"},
            ps: {flag: "🇦🇫"},
            uz: {flag: "🇺🇿"},
            en: {flag: "🇬🇧"},
            sq: {flag: "🇦🇱"},
            hy: {flag: "🇦🇲"},
            ru: {flag: "🇷🇺"},
            pt: {flag: "🇵🇹"},
            de: {flag: "🇩🇪"},
            nl: {flag: "🇳🇱"},
            sv: {flag: "🇸🇪"},
            az: {flag: "🇦🇿"},
            bs: {flag: "🇧🇦"},
            hr: {flag: "🇭🇷"},
            sr: {flag: "🇷🇸"},
            bn: {flag: "🇧🇩"},
            fr: {flag: "🇫🇷"},
            ff: {flag: "🇨🇳"},
            bg: {flag: "🇧🇬"},
            rn: {flag: "🇧🇮"},
            ms: {flag: "🇲🇾"},
            be: {flag: "🇧🇾"},
            ln: {flag: "🇨🇬"},
            sw: {flag: "🇹🇿"},
            lu: {flag: "🇨🇩"},
            sg: {flag: "🇨🇫"},
            it: {flag: "🇮🇹"},
            el: {flag: "🇬🇷"},
            tr: {flag: "🇹🇷"},
            cs: {flag: "🇨🇿"},
            da: {flag: "🇩🇰"},
            et: {flag: "🇪🇪"},
            ti: {flag: "🇪🇹"},
            fi: {flag: "🇫🇮"},
            hi: {flag: "🇮🇳"},
            ur: {flag: "🇵🇰"},
            fo: {flag: "🇫🇴"},
            ka: {flag: "🇬🇪"},
            kl: {flag: "🇬🇱"},
            hu: {flag: "🇭🇺"},
            id: {flag: "🇮🇩"},
            ga: {flag: "🇮🇪"},
            he: {flag: "🇮🇱"},
            fa: {flag: "🇮🇷"},
            is: {flag: "🇮🇸"},
            ja: {flag: "🇯🇵"},
            km: {flag: "🇰🇭"},
            ko: {flag: "🇰🇷"},
            kk: {flag: "🇰🇿"},
            si: {flag: "🇱🇰"},
            lt: {flag: "🇱🇹"},
            lv: {flag: "🇱🇻"},
            ro: {flag: "🇷🇴"},
            mg: {flag: "🇲🇬"},
            mk: {flag: "🇲🇰"},
            my: {flag: "🇲🇲"},
            mt: {flag: "🇲🇹"},
            af: {flag: "🇿🇦"},
            nb: {flag: "🇳🇴"},
            ne: {flag: "🇳🇵"},
            pl: {flag: "🇵🇱"},
            rw: {flag: "🇷🇼"},
            sl: {flag: "🇸🇮"},
            sk: {flag: "🇸🇰"},
            so: {flag: "🇸🇴"},
            th: {flag: "🇹🇭"},
            to: {flag: "🇹🇴"},
            uk: {flag: "🇺🇦"},
            vi: {flag: "🇻🇳"},
            sn: {flag: "🇿🇼"},
            AD: { flag: "🇦🇩" },
            AE: { flag: "🇦🇪" },
            AF: { flag: "🇦🇫" },
            AG: { flag: "🇦🇬" },
            AI: { flag: "🇦🇮" },
            AL: { flag: "🇦🇱" },
            AM: { flag: "🇦🇲" },
            AO: { flag: "🇦🇴" },
            AQ: { flag: "🇦🇶" },
            AR: { flag: "🇦🇷" },
            AS: { flag: "🇦🇸" },
            AT: { flag: "🇦🇹" },
            AU: { flag: "🇦🇺" },
            AW: { flag: "🇦🇼" },
            AX: { flag: "🇦🇽" },
            AZ: { flag: "🇦🇿" },
            BA: { flag: "🇧🇦" },
            BB: { flag: "🇧🇧" },
            BD: { flag: "🇧🇩" },
            BE: { flag: "🇧🇪" },
            BF: { flag: "🇧🇫" },
            BG: { flag: "🇧🇬" },
            BH: { flag: "🇧🇭" },
            BI: { flag: "🇧🇮" },
            BJ: { flag: "🇧🇯" },
            BL: { flag: "🇧🇱" },
            BM: { flag: "🇧🇲" },
            BN: { flag: "🇧🇳" },
            BO: { flag: "🇧🇴" },
            BQ: { flag: "🇧🇶" },
            BR: { flag: "🇧🇷" },
            BS: { flag: "🇧🇸" },
            BT: { flag: "🇧🇹" },
            BV: { flag: "🇧🇻" },
            BW: { flag: "🇧🇼" },
            BY: { flag: "🇧🇾" },
            BZ: { flag: "🇧🇿" },
            CA: { flag: "🇨🇦" },
            CC: { flag: "🇨🇨" },
            CD: { flag: "🇨🇩" },
            CF: { flag: "🇨🇫" },
            CG: { flag: "🇨🇬" },
            CH: { flag: "🇨🇭" },
            CI: { flag: "🇨🇮" },
            CK: { flag: "🇨🇰" },
            CL: { flag: "🇨🇱" },
            CM: { flag: "🇨🇲" },
            CN: { flag: "🇨🇳" },
            CO: { flag: "🇨🇴" },
            CR: { flag: "🇨🇷" },
            CU: { flag: "🇨🇺" },
            CV: { flag: "🇨🇻" },
            CW: { flag: "🇨🇼" },
            CX: { flag: "🇨🇽" },
            CY: { flag: "🇨🇾" },
            CZ: { flag: "🇨🇿" },
            DE: { flag: "🇩🇪" },
            DJ: { flag: "🇩🇯" },
            DK: { flag: "🇩🇰" },
            DM: { flag: "🇩🇲" },
            DO: { flag: "🇩🇴" },
            DZ: { flag: "🇩🇿" },
            EC: { flag: "🇪🇨" },
            EE: { flag: "🇪🇪" },
            EG: { flag: "🇪🇬" },
            EH: { flag: "🇪🇭" },
            ER: { flag: "🇪🇷" },
            ES: { flag: "🇪🇸" },
            ET: { flag: "🇪🇹" },
            FI: { flag: "🇫🇮" },
            FJ: { flag: "🇫🇯" },
            FK: { flag: "🇫🇰" },
            FM: { flag: "🇫🇲" },
            FO: { flag: "🇫🇴" },
            FR: { flag: "🇫🇷" },
            GA: { flag: "🇬🇦" },
            GB: { flag: "🇬🇧" },
            GD: { flag: "🇬🇩" },
            GE: { flag: "🇬🇪" },
            GF: { flag: "🇬🇫" },
            GG: { flag: "🇬🇬" },
            GH: { flag: "🇬🇭" },
            GI: { flag: "🇬🇮" },
            GL: { flag: "🇬🇱" },
            GM: { flag: "🇬🇲" },
            GN: { flag: "🇬🇳" },
            GP: { flag: "🇬🇵" },
            GQ: { flag: "🇬🇶" },
            GR: { flag: "🇬🇷" },
            GS: { flag: "🇬🇸" },
            GT: { flag: "🇬🇹" },
            GU: { flag: "🇬🇺" },
            GW: { flag: "🇬🇼" },
            GY: { flag: "🇬🇾" },
            HK: { flag: "🇭🇰" },
            HM: { flag: "🇭🇲" },
            HN: { flag: "🇭🇳" },
            HR: { flag: "🇭🇷" },
            HT: { flag: "🇭🇹" },
            HU: { flag: "🇭🇺" },
            ID: { flag: "🇮🇩" },
            IE: { flag: "🇮🇪" },
            IL: { flag: "🇮🇱" },
            IM: { flag: "🇮🇲" },
            IN: { flag: "🇮🇳" },
            IO: { flag: "🇮🇴" },
            IQ: { flag: "🇮🇶" },
            IR: { flag: "🇮🇷" },
            IS: { flag: "🇮🇸" },
            IT: { flag: "🇮🇹" },
            JE: { flag: "🇯🇪" },
            JM: { flag: "🇯🇲" },
            JO: { flag: "🇯🇴" },
            JP: { flag: "🇯🇵" },
            KE: { flag: "🇰🇪" },
            KG: { flag: "🇰🇬" },
            KH: { flag: "🇰🇭" },
            KI: { flag: "🇰🇮" },
            KM: { flag: "🇰🇲" },
            KN: { flag: "🇰🇳" },
            KP: { flag: "🇰🇵" },
            KR: { flag: "🇰🇷" },
            KW: { flag: "🇰🇼" },
            KY: { flag: "🇰🇾" },
            KZ: { flag: "🇰🇿" },
            LA: { flag: "🇱🇦" },
            LB: { flag: "🇱🇧" },
            LC: { flag: "🇱🇨" },
            LI: { flag: "🇱🇮" },
            LK: { flag: "🇱🇰" },
            LR: { flag: "🇱🇷" },
            LS: { flag: "🇱🇸" },
            LT: { flag: "🇱🇹" },
            LU: { flag: "🇱🇺" },
            LV: { flag: "🇱🇻" },
            LY: { flag: "🇱🇾" },
            MA: { flag: "🇲🇦" },
            MC: { flag: "🇲🇨" },
            MD: { flag: "🇲🇩" },
            ME: { flag: "🇲🇪" },
            MF: { flag: "🇲🇫" },
            MG: { flag: "🇲🇬" },
            MH: { flag: "🇲🇭" },
            MK: { flag: "🇲🇰" },
            ML: { flag: "🇲🇱" },
            MM: { flag: "🇲🇲" },
            MN: { flag: "🇲🇳" },
            MO: { flag: "🇲🇴" },
            MP: { flag: "🇲🇵" },
            MQ: { flag: "🇲🇶" },
            MR: { flag: "🇲🇷" },
            MS: { flag: "🇲🇸" },
            MT: { flag: "🇲🇹" },
            MU: { flag: "🇲🇺" },
            MV: { flag: "🇲🇻" },
            MW: { flag: "🇲🇼" },
            MX: { flag: "🇲🇽" },
            MY: { flag: "🇲🇾" },
            MZ: { flag: "🇲🇿" },
            NA: { flag: "🇳🇦" },
            NC: { flag: "🇳🇨" },
            NE: { flag: "🇳🇪" },
            NF: { flag: "🇳🇫" },
            NG: { flag: "🇳🇬" },
            NI: { flag: "🇳🇮" },
            NL: { flag: "🇳🇱" },
            NO: { flag: "🇳🇴" },
            NP: { flag: "🇳🇵" },
            NR: { flag: "🇳🇷" },
            NU: { flag: "🇳🇺" },
            NZ: { flag: "🇳🇿" },
            OM: { flag: "🇴🇲" },
            PA: { flag: "🇵🇦" },
            PE: { flag: "🇵🇪" },
            PF: { flag: "🇵🇫" },
            PG: { flag: "🇵🇬" },
            PH: { flag: "🇵🇭" },
            PK: { flag: "🇵🇰" },
            PL: { flag: "🇵🇱" },
            PM: { flag: "🇵🇲" },
            PN: { flag: "🇵🇳" },
            PR: { flag: "🇵🇷" },
            PS: { flag: "🇵🇸" },
            PT: { flag: "🇵🇹" },
            PW: { flag: "🇵🇼" },
            PY: { flag: "🇵🇾" },
            QA: { flag: "🇶🇦" },
            RE: { flag: "🇷🇪" },
            RO: { flag: "🇷🇴" },
            RS: { flag: "🇷🇸" },
            RU: { flag: "🇷🇺" },
            RW: { flag: "🇷🇼" },
            SA: { flag: "🇸🇦" },
            SB: { flag: "🇸🇧" },
            SC: { flag: "🇸🇨" },
            SD: { flag: "🇸🇩" },
            SE: { flag: "🇸🇪" },
            SG: { flag: "🇸🇬" },
            SH: { flag: "🇸🇭" },
            SI: { flag: "🇸🇮" },
            SJ: { flag: "🇸🇯" },
            SK: { flag: "🇸🇰" },
            SL: { flag: "🇸🇱" },
            SM: { flag: "🇸🇲" },
            SN: { flag: "🇸🇳" },
            SO: { flag: "🇸🇴" },
            SR: { flag: "🇸🇷" },
            SS: { flag: "🇸🇸" },
            ST: { flag: "🇸🇹" },
            SV: { flag: "🇸🇻" },
            SX: { flag: "🇸🇽" },
            SY: { flag: "🇸🇾" },
            SZ: { flag: "🇸🇿" },
            TC: { flag: "🇹🇨" },
            TD: { flag: "🇹🇩" },
            TF: { flag: "🇹🇫" },
            TG: { flag: "🇹🇬" },
            TH: { flag: "🇹🇭" },
            TJ: { flag: "🇹🇯" },
            TK: { flag: "🇹🇰" },
            TL: { flag: "🇹🇱" },
            TM: { flag: "🇹🇲" },
            TN: { flag: "🇹🇳" },
            TO: { flag: "🇹🇴" },
            TR: { flag: "🇹🇷" },
            TT: { flag: "🇹🇹" },
            TV: { flag: "🇹🇻" },
            TW: { flag: "🇹🇼" },
            TZ: { flag: "🇹🇿" },
            UA: { flag: "🇺🇦" },
            UG: { flag: "🇺🇬" },
            UM: { flag: "🇺🇲" },
            US: { flag: "🇺🇸" },
            UY: { flag: "🇺🇾" },
            UZ: { flag: "🇺🇿" },
            VA: { flag: "🇻🇦" },
            VC: { flag: "🇻🇨" },
            VE: { flag: "🇻🇪" },
            VG: { flag: "🇻🇬" },
            VI: { flag: "🇻🇮" },
            VN: { flag: "🇻🇳" },
            VU: { flag: "🇻🇺" },
            WF: { flag: "🇼🇫" },
            WS: { flag: "🇼🇸" },
            YE: { flag: "🇾🇪" },
            YT: { flag: "🇾🇹" },
            ZA: { flag: "🇿🇦" },
            ZM: { flag: "🇿🇲" },
            ZW: { flag: "🇿🇼" }
        }
    }
});
document.adoptStyleSheets(
    ["flags", ":root {" + Object.entries(document.languageInfo).map(([iso,{flag}])=>`--flag-${iso}: "${flag}";`).join("") + "}"],
    ["locale-flags", Object.keys(document.languageInfo).map(iso=>{
        return (/^[A-Z]/.test(iso) ? `[country="${iso}"]` : `[lang="${iso}"]`) + `{ --flag: var(--flag-${iso}); }`
    }).join("")]
);

