/* Copyright 2022- Martin Kufner */
Object.defineProperties(Date.prototype, {
    toZoneISOString: {
        value(withMsecs) {
            try {
                let {years, months, days, hours, minutes, seconds, milliseconds, zone} = this;
                [months, days, hours, minutes, seconds] = [months, days, hours, minutes, seconds].map(v => {
                    if(isNaN(v)) throw 'NaN';
                    return v.toString().padStart(2, '0');
                });
                const rv = [years, months, days].join("-") + "T" + [hours, minutes, seconds].join(":");
                return withMsecs ? `${rv}.${milliseconds}${zone}` : `${rv}${zone}`;

            }
            catch(e) {
                if(e !== "NaN") throw e;
                return "";
            }
        }
    },

    zone: {
        get() {
            const offset = this.getTimezoneOffset(),
                sign = offset > 0 ? '-' : '+',
                abs = Date.parse(Math.abs(offset) * 60 * 1000);
            return sign + (abs.getUTCHours() * 100 + abs.getUTCMinutes()).toString().padStart(4, '0');
        },
        set(zone) {
            this._zone = this.getTimezoneOffset() ? '' : 'UTC';
        }
    },
    getTimezone: {
        value() { return this.zone }
    },
    getTimezoneInfo: {
        value() {
            const long = (this.toString().match(/([^\(\)]+)\)/) || [])[1],
                short = long?.replace(/[^A-Z]/g, '');
            return {long, short, offset: this.getTimezone()};
        }
    },
    dayName: {
        get() { return this.toString().split(" ")[0]; }
    },
    monthName: {
        get() { return this.toString().split(" ")[1]; }
    },

    utc: {
        get() {
            this.zone = 'UTC';
            return this;
        }
    },

    get: {
        value(part) { return this[`get${this._zone || ''}${part}`](); }
    },
    set: {
        value(part, value) { return this[`set${this._zone || ''}${part}`](value); }
    },

    months: {
        get() { return this.get('Month') + 1 },
        set(value) { return this.set('Month', value - 1) }
    },
    years: {
        get() { return this.get('FullYear'); },
        set(value) { return this.set('FullYear', value); }
    },
    wday: {
        get() { return this.get('Day'); }
    },
    days: {
        get() { return this.get('Date'); },
        set(value) { return this.set('Date', value); }
    },
    hours: {
        get() { return this.get('Hours'); },
        set(value) { return this.set('Hours', value); }
    },
    minutes: {
        get() { return this.get('Minutes'); },
        set(value) { return this.set('Minutes', value); }
    },
    seconds: {
        get() { return this.get('Seconds'); },
        set(value) { return this.set('Seconds', value); }
    },
    milliseconds: {
        get() { return this.get('Milliseconds'); },
        set(value) { return this.set('Milliseconds', value); }
    },
    isValid: {
        get() { return !isNaN(this); }
    },
    msec: {
        get() { return this * 1; },
        set(value) {
            value = parseInt(value);
            if(isNaN(value)) return;
            this.setUTCFullYear(1970);
            this.setUTCMonth(0);
            this.setUTCDate(0);
            this.setUTCHours(0);
            this.setUTCMinutes(0);
            this.setUTCSeconds(0);
            this.setUTCMilliseconds(value);
        }
    },
    secf: {
        get() { return this.msec / 1000; },
        set(value) {
            this.msec = value * 1000;
        }
    },
    sec: {
        get() { return Math.floor(this.secf); },
        set(value) {
            this.secf = parseInt(value);
        }
    },
    adjustSec: {
        value(value) {
            this.secf += value
            return this;
        }
    },
    msFormatted: {
        get() {
            const hours = String(this.getHours()).padStart(2, '0');
            const minutes = String(this.getMinutes()).padStart(2, '0');
            const seconds = String(this.getSeconds()).padStart(2, '0');
            const milliseconds = String(this.getMilliseconds()).padStart(3, '0');
            return `${hours}:${minutes}:${seconds}.${milliseconds}`;
        }
    }
});


Object.defineProperties(Date, {
    msFormatted: {
        get() {
            return new Date().msFormatted;
        }
    },

    packed: {
        get() { return this.now().pack62 }
    },

    utc: {
        value(value, ...args) {
            if(typeof value === "string") {
                if(!/UTC|[+-]\d\d:?\d\d|Z$/.test(value)) value += "Z";
            }
            else if(args.length) value = Date.UTC(value, ...args);
            return new Date(value);
        }
    },

    timeId: {
        get() {
            const rand = Math.floor(Math.random() * 36 * 36 - 0.1).toString(36);
            return this.now().toString(36) + rand;
        }
    },
    msec: {
        get() { return this.now(); }
    },
    sec: {
        get() { return this.now().sec; }
    },
    secf: {
        get() { return this.now().secf; }
    },
    timezoneName: {
        get() { return new this().timezoneName; }
    },
    getTimezone: {
        get() { return new this().getTimezone; }
    },
    parse: {
        value(string) { return new this(string) }
    }
});


